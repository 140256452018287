import React from "react";
import homelogo from "../images/temp/StIcon.svg";

const HomeComponent = () => {
  return (
    <div className="homelogo">
      <div className="logospace">
        <img src={homelogo} alt="" />
      </div>
    </div>
  );
};
export default HomeComponent;
