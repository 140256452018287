// Author: R Rahul, c706
import React, { Component } from "react";
import { Radar } from "react-chartjs-2";

import "react-svg-radar-chart/build/css/index.css";

class SpiderGraph extends Component {
  render() {
    let labels;
    let myArray = [];
    const { personalityInsight, personalityGraphData } = this.props;

    // From Personality Insight
    if (personalityInsight) {
      personalityGraphData.forEach((data, index) => {
        if (data.percentile) {
          myArray[index] = Math.round(data.percentile * 100);
        } else {
          myArray[index] = 0;
        }
      });
      labels = [
        `Openness ${myArray[0]}%`,
        `Conscientiousness ${myArray[1]}%`,
        `Extraversion ${myArray[2]}%`,
        `Agreeableness ${myArray[3]}%`,
        `Emotional Range ${myArray[4]}%`
      ];
    }
    // Normal
    else {
      let myObject = Object.assign({}, this.props.data.emotion);
      Object.keys(this.props.data.emotion).map(key => (myObject[key] *= 100));

      myArray[0] = Math.round(myObject.joy);
      myArray[1] = Math.round(myObject.anger);
      myArray[2] = Math.round(myObject.disgust);
      myArray[3] = Math.round(myObject.sadness);
      myArray[4] = Math.round(myObject.fear);

      labels = [
        `Joy ${myArray[0]}%`,
        `Anger ${myArray[1]}%`,
        `Disgust ${myArray[2]}%`,
        `Sadness ${myArray[3]}%`,
        `Fear ${myArray[4]}%`
      ];
    }
    const data = {
      labels,
      datasets: [
        {
          label: "",
          fill: true,
          lineTension: 0,
          backgroundColor: "rgba(120, 94, 240,0.4)",
          borderColor: "rgba(120, 94, 240, 1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: myArray
        }
      ]
    };

    return (
      <div className=" col-md-6">
        <div className="analyze-box lan-ht">
          <h5>{this.props.heading}</h5>
          <div className="status-box">
            <Radar
              ref="chart"
              data={data}
              options={{
                legend: false,
                scale: {
                  pointLabels: {
                    fontStyle: "bold",
                    fontColor: "rgba(120, 94, 240, 1)",
                    fontSize: 12
                  },
                  gridLines: {
                    color: "#898989",
                    lineWidth: 1,
                    offsetGridLines: true
                  },
                  ticks: {
                    fontSize: 20,
                    backdropColor: "rgba(255,255,255,0)",
                    userCallback: () => "",
                    beginAtZero: true,
                    min: 0,
                    max: 100,
                    stepSize: 25
                  }
                },
                tooltips: {
                  enabled: false
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SpiderGraph;
