/* eslint-disable */
// Author: R Rahul, c706
import React, { Component } from "react";
import axios from "axios";
import renderHTML from "react-render-html";
import { SyncLoader } from "react-spinners";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ReactMic } from "react-mic";
import Alert from "react-s-alert";

import baseURL from "./BaseURL";
import Wrapper from "./Wrapper";

import "react-perfect-scrollbar/dist/css/styles.css";
import homelogo from "../images/temp/StIcon500px (1).png";
import link from "../images/temp/link.png";
import mic from "../images/temp/mic-new.png";
import settingsbutton from "../images/temp/Settings-Icon.svg";
import smiley from "../images/temp/user-new.png";

class ExpertAssist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      contextId: null,
      documentSearchText: "",
      documentSet: [],
      documentView: "html",
      engagementTracker: false,
      loading: false,
      menuActive: this.props.menuActive,
      messages: [],
      newMessage: "",
      record: false,
      selecteddocumentSet: "",
      setactive: this.props.settingsActive,
      smartSearch: false,
      speaker: "user",
      totalPage: 1
    };
  }

  componentDidMount() {
    this.setState({ contextId: Math.random() });
    this.setState({ workspace: "49320772-73b5-4f5b-89b8-375fa88330f5" });
    const bearer =
      "Bearer " +
      JSON.parse(localStorage.getItem("userAccessToken")).accessToken;

    axios
      .get(`${baseURL}/api/listcollection`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer
        },

        credentials: "include"
      })
      .then(res => {
        this.setState({
          documentSet: res.data.collections,
          selecteddocumentSet: res.data.collections[0].collection_id
        });
      })
      .catch(() => {});
  }

  //New updated props from header.js to toggle settings and menu view
  componentWillReceiveProps = newProps => {
    this.setState({
      setactive: newProps.settingsActive,
      menuActive: newProps.menuActive
    });
  };

  clearAllData = () => {
    this.setState({
      messages: [],
      contentToShow: undefined,
      searchResults: undefined,
      documentSearchText: "",
      activePage: 1
    });
  };

  // Used to handle the settings button click
  settingClick = () => {
    this.props.settingsClicked(); //Call back to parent component(Header.js)
  };

  // This function is used to automatically scroll the chat window as new messages are added
  scrollToBottom = () => {
    if (this.refs.messagesEnd) {
      this.refs.messagesEnd._container.scrollTop = this.refs.scrollArea.clientHeight;
    }
  };

  //When component is updated, (new messages are typed), sc
  componentDidUpdate() {
    this.scrollToBottom();
  }

  //To calculate the time of sending message
  currentTime = () => {
    var today = new Date();

    var hour = today.getHours();
    var minute = today.getMinutes();
    var status = "am";

    // if greater than 12 hours, reduce to 12 hour format and insert pm
    if (hour > 12) {
      hour = hour - 12;
      status = "pm";
    }
    // If time is less than 10, add 0 as prefix  so as to show time 05:05pm rather than 5:5pm
    if (minute < 10) {
      minute = "0" + minute;
    }
    if (hour < 10) {
      hour = "0" + hour;
    }
    return hour + ":" + minute + " " + status;
  };

  // Typing contents into the message box
  handleMessageTyping = msg => {
    this.setState({ newMessage: msg });
  };

  // Sending the message in message box by pressing enter key
  handleMessageSending = (event, recording) => {
    if (recording || event.key === "Enter") {
      if (event) event.preventDefault();
      if (this.state.newMessage) {
        this.setState(
          {
            messages: [
              ...this.state.messages,
              {
                id: new Date().getTime().toString() + Math.random(),
                from: this.state.speaker,
                text: this.state.newMessage,
                isTone: this.state.engagementTracker,
                isIndent: this.state.smartSearch
              }
            ]
          },
          () => {
            this.setState({ newMessage: "" });
          }
        );

        this.setState(
          { speaker: this.state.speaker === "user" ? "agent" : "user" },
          () => {
            //If Engagement tracker and smart search or engagement tracker alone is ON
            if (this.state.engagementTracker && this.state.speaker === "user") {
              const bearer =
                "Bearer " +
                JSON.parse(localStorage.getItem("userAccessToken")).accessToken;

              axios
                .post(`${baseURL}/api/conversationclick`, {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: bearer
                  },

                  credentials: "include",
                  audioMode: false,
                  collection_id: this.state.selecteddocumentSet,
                  context: { id: this.state.contextId },
                  natural_language_query: this.state.newMessage,
                  tone: true,
                  workspace: "CX",
                  workspace_id: this.state.workspace
                })
                .then(res => {
                  const intentResponse = res.data.result.response.output;
                  let messageIntent;
                  let messageTones;

                  // Assigning tones to a variable to add in the message list to update state
                  messageTones = res.data.result.toneAnalysis.utterances_tone[0]
                    .tones.length
                    ? res.data.result.toneAnalysis.utterances_tone[0].tones.map(
                        tone => tone.tone_name
                      )
                    : [];
                  if (
                    this.state.speaker === "agent" &&
                    this.state.smartSearch
                  ) {
                    messageIntent = intentResponse.actions
                      ? intentResponse.actions.length > 0 &&
                        intentResponse.actions[0].parameters &&
                        intentResponse.actions[0].parameters.result
                      : "";
                  }
                  // Storing messages in  temporaryHolder to update the state with tone and/or intent on the last message
                  const temporaryHolder = this.state.messages;
                  temporaryHolder[
                    temporaryHolder.length - 1
                  ].tones = messageTones;

                  this.state.smartSearch
                    ? (temporaryHolder[
                        temporaryHolder.length - 1
                      ].intent = messageIntent)
                    : null;

                  this.setState({ messages: temporaryHolder });
                });
            }

            //If Smart Search is ON
            if (
              (this.state.smartSearch || this.state.engagementTracker) &&
              this.state.speaker === "agent"
            ) {
              const bearer =
                "Bearer " +
                JSON.parse(localStorage.getItem("userAccessToken")).accessToken;

              this.state.speaker === "agent" &&
                axios
                  .post(`${baseURL}/api/conversationclick`, {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: bearer
                    },

                    credentials: "include",
                    audioMode: false,
                    collection_id: this.state.selecteddocumentSet,
                    context: { id: this.state.contextId },
                    natural_language_query: this.state.newMessage,
                    tone: this.state.engagementTracker,
                    workspace: "CX",
                    workspace_id: this.state.workspace
                  })
                  .then(res => {
                    //Since state change to agent and user works before the API response, we have to check whether the speaker is agent so as to assign intent for the user message.
                    const intentResponse = res.data.result.response.output;
                    if (this.state.speaker === "agent") {
                      const messageIntent = intentResponse.actions
                        ? intentResponse.actions.length > 0 &&
                          intentResponse.actions[0].parameters &&
                          intentResponse.actions[0].parameters.result
                        : "";

                      // Storing messages in  temporaryHolder to update the state with intent on the last message
                      const temporaryHolder = this.state.messages;
                      temporaryHolder[
                        temporaryHolder.length - 1
                      ].intent = messageIntent;

                      if (this.state.engagementTracker) {
                        let messageTones;
                        // Assigning tones to a variable to add in the message list to update state
                        messageTones = res.data.result.toneAnalysis
                          .utterances_tone[0].tones.length
                          ? res.data.result.toneAnalysis.utterances_tone[0].tones.map(
                              tone => tone.tone_name
                            )
                          : [];

                        temporaryHolder[
                          temporaryHolder.length - 1
                        ].tones = messageTones;
                      }
                      this.setState({ messages: temporaryHolder });
                    }

                    //If smart search is on, then the text typed in chat section will be chosen as the text to be searched to show on the document view, if action[0].name is search

                    if (
                      this.state.speaker === "agent" &&
                      this.state.smartSearch &&
                      intentResponse.actions &&
                      intentResponse.actions.length > 0 &&
                      intentResponse.actions[0].name === "Search"
                    ) {
                      this.setState({ loading: true, activePage: 1 });
                      !recording &&
                        this.setState({
                          documentSearchText:
                            intentResponse.actions[0].parameters.query
                        });
                      const bearer =
                        "Bearer " +
                        JSON.parse(localStorage.getItem("userAccessToken"))
                          .accessToken;
                      axios
                        .post(`${baseURL}/api/discovery`, {
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: bearer
                          },

                          credentials: "include",
                          audioMode: false,
                          context: { id: this.state.contextId },
                          collection_id: this.state.selecteddocumentSet,
                          natural_language_query: this.state.documentSearchText,
                          tone: this.state.engagementTracker,
                          workspace: "MS",
                          workspace_id: this.state.workspace
                        })
                        .then(res => {
                          this.setState(
                            {
                              loading: false,
                              searchResults: res.data.result.result.results,
                              passages: res.data.result.result.passages
                            },
                            () => this.highlightText()
                          );
                        })
                        .catch(err => {
                          this.setState({ loading: false });
                        });
                    }
                  });
            }
          }
        );
      }
    }
  };

  //When a user clicks the thumbs up button on the document view. Once you have liked the document, you will not be able to relike or unlike it
  documentLiked = id => {
    const temporaryHolder = this.state.searchResults;

    temporaryHolder.map((result, index) => {
      if (result.id === id) {
        if (!result.liked) {
          temporaryHolder[index].liked = true;
          this.setState({ searchResults: temporaryHolder });
          const bearer =
            "Bearer " +
            JSON.parse(localStorage.getItem("userAccessToken")).accessToken;
          axios.post(`${baseURL}/api/events`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: bearer
            },

            credentials: "include"
          });
        }
      }
    });
  };

  // On changing drop down for Document Set
  documentSetChange = collection_id => {
    this.clearAllData();
    this.setState({
      selecteddocumentSet: collection_id
    });
  };

  // Saving value of search query in the state
  handleDocumentSearch = text => {
    this.setState({ documentSearchText: text });
  };

  //Function watching for enter key to be pressed so as to process input query
  watchSubmitting = (event, recording) => {
    if (
      (recording && this.state.documentSearchText) ||
      (event.key === "Enter" && this.state.documentSearchText)
    ) {
      if (event) event.preventDefault();
      // Posting to API, the values in search query, to get response from agent
      this.setState({ loading: true, activePage: 1 });
      const bearer =
        "Bearer " +
        JSON.parse(localStorage.getItem("userAccessToken")).accessToken;
      axios
        .post(`${baseURL}/api/discovery`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: bearer
          },

          credentials: "include",
          audioMode: false,
          context: { id: this.state.contextId },
          collection_id: this.state.selecteddocumentSet,
          natural_language_query: this.state.documentSearchText,
          tone: this.state.engagementTracker,
          workspace: "MS",
          workspace_id: this.state.workspace
        })
        .then(res => {
          this.setState(
            {
              loading: false,
              searchResults: res.data.result.result.results,
              passages: res.data.result.result.passages
            },
            () => this.highlightText()
          );
        })

        .catch(err => {
          this.setState({ loading: false });
        });
    }
  };

  getTone = message => {
    let tone;
    let intent;
    if (message.isTone && message.isIndent) {
      if (message.from === "agent") {
        tone = message.tones
          ? message.tones.length > 0
            ? `${message.tones}`
            : `None`
          : "";
        intent = "";
      } else {
        tone = message.tones
          ? message.tones.length > 0
            ? `Tone: ${message.tones}`
            : `Tone: None`
          : "";
        intent = message.intent ? message.intent : "";
      }

      // If agent Tone, then only show the tone instead of Tone: "tone_name". Also if tone and intent is empty, then show nothing
      return intent.length || tone.length
        ? message.from === "agent"
          ? `${tone}`
          : intent
          ? `${intent} | ${tone}`
          : `${tone}`
        : "";
    } else if (!message.isTone && message.isIndent) {
      return message.intent && `${message.intent}`;
    } else if (message.isTone && !message.isIndent) {
      tone = message.tones
        ? message.tones.length > 0
          ? `Tone: ${message.tones}`
          : `Tone: None`
        : "";
      return tone;
    } else {
      return "";
    }
  };

  toggleRecording = () => {
    this.props.menuActive && this.props.menuClicked();
    this.props.settingsActive && this.props.settingsClicked();
    this.setState({
      record: !this.state.record
    });
  };

  onStop = recordedBlob => {
    if (recordedBlob.blob.size / 1024 / 1024 > 2) {
      Alert.error("File size exceeded", {
        position: "bottom-right",
        effect: "slide",
        timeout: 3000
      });
      return;
    }
    var fileName = recordedBlob.blobURL.split("/");
    this.uploadVoiceSearchData({
      blob: recordedBlob.blob,
      audioName: fileName
    });
  };

  uploadVoiceSearchData = data => {
    const formData = new FormData();
    formData.append("audio", data.blob, data.audioName[3]);

    axios
      .post(`${baseURL}/api/sta`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(res => {
        const recording = true;
        this.state.smartSearch && this.state.speaker === "user"
          ? this.setState({
              documentSearchText: res.data.transcription,
              newMessage: res.data.transcription
            })
          : this.setState({ newMessage: res.data.transcription });

        if (!res.data.transcription) {
          Alert.error("Unable to detect speech", {
            position: "bottom-right",
            effect: "slide",
            timeout: 3000
          });
        } else {
          this.handleMessageSending(null, recording);
        }
      })
      .catch(() => {});
  };

  highlightText = () => {
    let contentToShow = [];

    for (var i = 0; i < this.state.passages.length; i++)
      for (var j = 0; j < this.state.searchResults.length; j++)
        if (
          this.state.passages[i].document_id === this.state.searchResults[j].id
        ) {
          contentToShow.push({
            id: new Date().getTime().toString() + Math.random(),
            document_id: this.state.passages[i].document_id,
            passageTitle: this.state.searchResults[j].metadata.title,
            passageText: this.state.passages[i].passage_text,
            start_offset: this.state.passages[i].start_offset,
            end_offset: this.state.passages[i].end_offset,
            url: this.state.searchResults[j].metadata.url,
            html: this.state.searchResults[j].html,
            liked: false
          });
        }
    this.setState(
      {
        contentToShow
      },
      () =>
        this.setState({ totalPage: contentToShow.length }, () =>
          this.colourText()
        )
    );
  };

  nextPage = () => {
    this.state.activePage < this.state.totalPage &&
      this.setState(
        {
          activePage: this.state.activePage + 1
        },
        () => this.colourText()
      );
  };
  previousPage = () => {
    this.state.activePage > 1 &&
      this.setState(
        {
          activePage: this.state.activePage - 1
        },
        () => this.colourText()
      );
  };

  colourText = () => {
    this.state.contentToShow.map((content, index) => {
      if (this.state.activePage === index + 1) {
        const htmlTextToColour = content.passageText.replace("no title", "");

        var markInstance = new Mark(document.querySelector(".context"));
        var keyword = htmlTextToColour;

        var options = {
          acrossElements: true,
          debug: false,
          diacritics: true,
          separateWordSearch: false
        };

        markInstance.unmark({
          done: function() {
            markInstance.mark(keyword, options);
          }
        });
      }
    });
    document.querySelector("mark") &&
      document.querySelector("mark").scrollIntoView({ behavior: "smooth" });
  };

  render() {
    return (
      <Wrapper>
        <div>
          {/* Settings button for advanced options */}
          <div
            className={
              this.state.setactive
                ? "settingtbutton-img active"
                : "settingtbutton-img"
            }
            onClick={this.settingClick}
          >
            <img src={settingsbutton} alt="" />
          </div>

          <div
            className={
              this.state.setactive ? "st-settings active" : "st-settings"
            }
          >
            <div className={this.state.loader ? "settings-overlay" : ""}>
              <ul className="st-setting-sec">
                {/* Sidebar (Advanced settings) contents */}
                <li>
                  <div>
                    <label>Document Set</label>
                    <div className="select-drops">
                      <select
                        className="form-control"
                        onChange={e => this.documentSetChange(e.target.value)}
                      >
                        {this.state.documentSet.map(value => (
                          <option
                            value={value.collection_id}
                            key={value.collection_id}
                          >
                            {value.name}
                          </option>
                        ))}
                      </select>
                      <i className="fa fa-caret-down" aria-hidden="true" />
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <label>Assistant</label>

                    <div className="select-drops">
                      <select
                        disabled={!this.state.smartSearch}
                        className="form-control"
                        onChange={event => {
                          this.clearAllData();
                          this.setState({ workspace: event.target.value });
                        }}
                      >
                        <option value={this.state.workspace}>
                          Expert Assist
                        </option>
                        ))}
                      </select>
                      <i className="fa fa-caret-down" aria-hidden="true" />
                    </div>
                  </div>
                </li>
                {/* Smart Search */}
                <li>
                  <label>Smart Search</label>
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={() =>
                        this.setState({
                          smartSearch: !this.state.smartSearch
                        })
                      }
                    />
                    <span className="slider round" />
                  </label>
                </li>

                {/* Tone analyzer */}
                <li>
                  <label>Engagement Tracker</label>
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={() =>
                        this.setState({
                          engagementTracker: !this.state.engagementTracker
                        })
                      }
                    />
                    <span className="slider round" />
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section className="inner-sections">
          <div className="row">
            <div className="col-md-4">
              <div className="search-areas">
                <div className="st-chat-window clearfix remove-iphonex">
                  <div className="chat-top">
                    {/* Messages displayed here */}

                    <PerfectScrollbar ref="messagesEnd">
                      <div className="chat-history" ref="scrollArea">
                        <ul>
                          <li>
                            {this.state.messages.map(message => {
                              return (
                                // If the response is text
                                (message.text && (
                                  <div
                                    key={message.id}
                                    className="message-data"
                                  >
                                    {message.from === "agent" ? (
                                      <div className="clearfix">
                                        <div className="message-data-api">
                                          <img src={mic} />
                                          <span className="msg-time">
                                            <b>{this.currentTime()}</b>
                                          </span>
                                          {renderHTML(message.text)}

                                          <span className="chatstatus">
                                            {this.getTone(message)}
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="clearfix">
                                        <div className="message-data-user">
                                          <small>
                                            <b>{this.currentTime()}</b>
                                          </small>
                                          <p>{message.text}</p>
                                          <span className="chatstatus">
                                            {this.getTone(message)}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )) ||
                                // If the response is an image
                                (message.image && (
                                  <div
                                    key={message.id}
                                    className="message-data"
                                  >
                                    <div className="clearfix">
                                      <div className="message-data-api">
                                        <small>
                                          <b>{this.currentTime()}</b>
                                        </small>
                                        <img src={message.image} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                ))
                              );
                            })}
                          </li>
                        </ul>
                      </div>
                    </PerfectScrollbar>
                  </div>
                  {/* Loading animation till API result */}
                  {this.state.chatLoading && (
                    <div className="text-center" style={{ marginTop: "-75px" }}>
                      <SyncLoader
                        color={"#785ef0"}
                        size={8}
                        loading={this.props.loader}
                      />
                    </div>
                  )}
                </div>
                {/* Type your messages here (Assistant User conversation) */}
                <form>
                  <input
                    onClick={() => {
                      this.props.menuActive && this.props.menuClicked();
                      this.props.settingsActive && this.props.settingsClicked();
                    }}
                    type="text"
                    name="message-to-send"
                    className="form-control"
                    value={this.state.newMessage}
                    id="message-to-send"
                    placeholder="Type your message"
                    required=""
                    onChange={event =>
                      this.handleMessageTyping(event.target.value)
                    }
                    onKeyPress={event => this.handleMessageSending(event)}
                  />

                  <button
                    type="button"
                    onClick={this.toggleRecording}
                    className={
                      this.state.record
                        ? "btn-record pull-left active"
                        : "btn-record pull-left"
                    }
                  >
                    <i className="fa fa-microphone" aria-hidden="true" />
                  </button>
                  <span className="hide">
                    <ReactMic
                      record={this.state.record}
                      className="sound-wave"
                      onStop={this.onStop}
                    />
                  </span>
                </form>
                {/* Assistant User Togglers */}

                {/* Agent */}
                <div className="chat-toggle">
                  <img
                    src={mic}
                    alt=""
                    onClick={() => this.setState({ speaker: "agent" })}
                    className={
                      this.state.speaker === "agent"
                        ? "active pointer"
                        : "pointer"
                    }
                  />

                  {/* User */}
                  <img
                    src={smiley}
                    alt=""
                    onClick={() => this.setState({ speaker: "user" })}
                    className={
                      this.state.speaker === "user"
                        ? "active pointer"
                        : "pointer"
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="newtab-area">
                {/* Document Search bar  */}
                <div className="search-areas">
                  <form>
                    <input
                      onClick={() => {
                        this.props.menuActive && this.props.menuClicked();
                        this.props.settingsActive &&
                          this.props.settingsClicked();
                      }}
                      type="text"
                      name="message-to-send"
                      className="form-control"
                      value={this.state.documentSearchText}
                      id="message-to-send"
                      placeholder="Natural language search"
                      required=""
                      onChange={event =>
                        this.handleDocumentSearch(event.target.value)
                      }
                      onKeyPress={event => this.watchSubmitting(event)}
                    />
                    {this.state.loading && (
                      <div
                        className="text-center"
                        style={{ marginTop: "30px" }}
                      >
                        <SyncLoader
                          color={"#785ef0"}
                          size={8}
                          loading={this.props.loader}
                        />
                      </div>
                    )}
                  </form>
                </div>
                {!this.state.loading && (
                  <div className="expert-tab">
                    {this.state.contentToShow ? (
                      this.state.contentToShow.length ? (
                        this.state.contentToShow.map((content, index) => {
                          return (
                            index + 1 === this.state.activePage && (
                              <div
                                key={content.id}
                                id="home"
                                className="tab-pane fade in active"
                              >
                                <div className="row">
                                  <div className="col-sm-6">
                                    <h4 className="dis-caption">
                                      {content.passageTitle
                                        ? content.passageTitle
                                        : "No Title Available"}{" "}
                                      <i
                                        onClick={() => {
                                          this.documentLiked(content.id);
                                        }}
                                        style={
                                          content.liked
                                            ? {
                                                color: "#000",
                                                cursor: "pointer"
                                              }
                                            : { cursor: "pointer" }
                                        }
                                        className="fa fa-thumbs-up"
                                        aria-hidden="true"
                                      />
                                    </h4>
                                  </div>

                                  <div className="col-sm-6">
                                    <div className="innertabarea">
                                      <ul className="nav nav-tabs">
                                        <li
                                          className={
                                            this.state.documentView === "html"
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          <a
                                            data-toggle="tab"
                                            onClick={() =>
                                              this.setState(
                                                {
                                                  documentView: "html"
                                                },
                                                () => this.highlightText()
                                              )
                                            }
                                          >
                                            HTML
                                          </a>
                                        </li>
                                        <li
                                          className={
                                            this.state.documentView === "inline"
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          <a
                                            data-toggle="tab"
                                            href="#"
                                            onClick={() =>
                                              this.setState({
                                                documentView: "inline"
                                              })
                                            }
                                          >
                                            Document Inline
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href={content.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Document External{" "}
                                            <img src={link} alt="" />
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>

                                <div className="tab-content tab-content-inner">
                                  <div
                                    id="html"
                                    className="tab-pane fade in active"
                                  >
                                    {this.state.documentView === "html" ? (
                                      renderHTML(
                                        `<div className="context">${
                                          content.html
                                        }</div>`
                                      )
                                    ) : (
                                      <div className="iframe-space">
                                        <iframe
                                          title="DocumentView"
                                          src={content.url}
                                          width="100%"
                                          height="100%"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                          );
                        })
                      ) : (
                        "No data found"
                      )
                    ) : (
                      <div className="newtab-area">
                        <div className="homelogo" style={{ height: "50vh" }}>
                          <div className="logospace">
                            <img src={homelogo} alt="" />
                          </div>
                        </div>
                      </div>
                    )}

                    {this.state.contentToShow &&
                    this.state.searchResults.length ? (
                      <div className="custom-pagination">
                        <span className="result-page">Result</span>
                        <span className="values-page">
                          {this.state.activePage}/{this.state.totalPage}
                        </span>
                        <span className="pre-page">
                          <a>
                            <i
                              className="fa fa-angle-left"
                              aria-hidden="true"
                              onClick={() => this.previousPage()}
                            />
                          </a>
                        </span>
                        <span className="next-page">
                          <a>
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                              onClick={() => this.nextPage()}
                            />
                          </a>
                        </span>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </Wrapper>
    );
  }
}
export default ExpertAssist;
