import React, { Component } from "react";
import "../css/style.css";
import { Tabs, Tab } from "react-bootstrap";
import { SyncLoader } from "react-spinners";
import { ScaleLoader } from "react-spinners";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";

const initialState = {
  type: "text",
  valueText: "",
  valueUrl: "",
  valueAudio: ""
};

class InputAnalyze extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleChangeUrl = this.handleChangeUrl.bind(this);
    this.windowRefresh = this.windowRefresh.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidMount() {
    window.addEventListener("load", this.windowRefresh);
  }

  windowRefresh() {
    localStorage.removeItem("input");
    this.reset();
  }

  componentWillMount() {
    if (localStorage.getItem("input")) {
      let test = JSON.parse(localStorage.getItem("input"));
      if (test.type === "text") {
        this.setState({ valueText: test.data });
      } else if (test.type === "url") {
        this.setState({ valueUrl: test.data });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("load", this.windowRefresh);
  }

  handleChangeText(event) {
    this.setState({
      valueText: event.target.value,
      type: this.state.type
    });
  }

  handleChangeUrl(event) {
    this.setState({
      valueUrl: event.target.value,
      type: this.state.type
    });
  }

  reset() {
    this.setState(initialState);
  }

  analyzeText(that, type) {
    if (type === "text") {
      that.setState({
        type: "text"
      });
      localStorage.setItem(
        "input",
        JSON.stringify({ data: that.state.valueText, type: that.state.type })
      );
      that.props.fetchData("text", that.state.valueText, this.state.loader);
      that.props.fetchDataLan("text", that.state.valueText, this.state.loader);
    } else if (type === "url") {
      that.setState({
        type: "url"
      });
      localStorage.setItem(
        "input",
        JSON.stringify({ data: that.state.valueUrl, type: that.state.type })
      );
      that.props.fetchData("url", that.state.valueUrl, this.state.loader);
      that.props.fetchDataLan("url", that.state.valueUrl, this.state.loader);
    }
  }

  componentWillReceiveProps() {}

  handleSelect(key) {
    if (!localStorage.getItem("input")) {
      this.reset();
    }
    if (key === 2) {
      this.setState({
        type: "url"
      });
    } else {
      this.setState({
        type: "text"
      });
    }
  }

  // fileUpload(file, blobName) {
  //   this.setState({
  //     load: true
  //   });
  //   const formData = new FormData();
  //   if (!blobName) {
  //     formData.append("audio", file);
  //   } else {
  //     formData.append("audio", file, blobName[3]);
  //   }
  //   axios
  //     .post("https://spacetime-back.au-syd.mybluemix.net/api/st", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data"
  //       }
  //     })
  //     .then(response => {
  //       if (response.data.err) {
  //         let err1 = response.data.err;
  //         if (response.data.error_code === 400) {
  //           err1 = "Unsupported file format";
  //         }
  //         Alert.error(err1, {
  //           position: "bottom-right",
  //           effect: "slide",
  //           timeout: 3500
  //         });
  //         this.setState({ load: false });
  //       } else
  //         this.setState({
  //           valueText: response.data.transcription,
  //           load: false
  //         });
  //     });
  // }

  render() {
    return (
      <div className="text-tab">
        <Tabs
          defaultActiveKey={1}
          id="uncontrolled-tab-example"
          onSelect={this.handleSelect.bind(this)}
        >
          <Tab eventKey={1} title="Text">
            <textarea
              className="form-control textarea-tb"
              rows="4"
              id="comment"
              value={this.state.valueText}
              placeholder="Enter text..."
              onChange={this.handleChangeText.bind(this)}
            >
              {" "}
            </textarea>
            <div className="button-sect text-right">
              {/* This code was commented so that it can be retained easily if the client wants it back */}

              {/* <span className="btn-upload">
                <input
                  type="file"
                  accept="audio/*"
                  onChange={this.handleAudio.bind(this)}
                />
                Upload Audio
              </span> */}
              {/* <button
                type="button"
                onClick={this.startRecording.bind(this)}
                className="btn-default btn-style btn-record pull-left"
              >
                <i className="fa fa-microphone" aria-hidden="true" />{" "}
                {this.state.record ? "Recording.." : "Record"}
              </button> */}
              {/* <span className="hide">
                <ReactMic
                  record={this.state.record}
                  onStop={this.onStop.bind(this)}
                />
              </span> */}
              {this.state.load && (
                <div className="loader">
                  <ScaleLoader color={"#785ef0"} loading={this.state.load} />
                </div>
              )}
              {/* This code was commented so that it can be retained easily if the client wants it back */}

              {/* <button
                type="button"
                className="btn-default btn-style"
                onClick={this.reset}
              >
                Clear
              </button> */}
              <button
                type="submit"
                disabled={this.state.valueText.trim() === ""}
                value="submit"
                onClick={() => this.analyzeText(this, "text")}
                className="btn-default btn-style"
              >
                Analyse
              </button>
            </div>
          </Tab>
          <Tab eventKey={2} title="Link">
            <input
              type="text"
              className="form-control textbox textarea-tb"
              placeholder="Enter URL Link..."
              value={this.state.valueUrl}
              onChange={this.handleChangeUrl.bind(this)}
            />
            <div className="button-sect text-right">
              {/* This code was commented so that it can be retained easily if the client wants it back */}

              {/* <button
                    type="button"
                    className="btn-default btn-style"
                    onClick={this.reset}
                  >
                    Clear
                  </button> */}
              <button
                type="submit"
                disabled={this.state.valueUrl.trim() === ""}
                value="submit"
                onClick={() => this.analyzeText(this, "url")}
                className="btn-default btn-style"
              >
                Analyse
              </button>
            </div>
          </Tab>
          {/* This code was commented so that it can be retained easily if the client wants it back */}

          {/* <Tab eventKey={3} title="Audio">
            <span className="btn-upload">
                <input type="file" accept="audio/*" onChange={this.handleAudio.bind(this)} />
                Upload File
            </span>
            <button type="button" onClick={this.startRecording.bind(this)} className="btn-default btn-style btn-record"><i className="fa fa-microphone" aria-hidden="true"></i> {this.state.record ? 'Recording..' : 'Record'}</button>
            <span className="hide">
              <ReactMic
                record={this.state.record}
                onStop={this.onStop.bind(this)}
              />
            </span>
            {this.state.valueAudio !== '' && <textarea type="text" className="form-control textbox voice-transcription" placeholder="Audio" value={this.state.valueAudio} onChange={this.handleChangeAudio.bind(this)} />}
            <div className="button-sect text-right">
              <button type="button" className="btn-default btn-style" onClick={this.reset}>Clear</button>
              <button type="submit" disabled={this.state.valueAudio.trim() === ''} value="submit" onClick={() => this.analyzeText(this, 'audio')} className="btn-default btn-style">Analyze</button>
            </div>
          </Tab> */}
        </Tabs>
        {this.props.loader ? (
          <div className="text-center" style={{ marginTop: "35px" }}>
            <SyncLoader color={"#785ef0"} loading={this.state.loading} />
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }
}

export default InputAnalyze;
