import React, { Component } from "react";
import axios from "axios";
import Alert from "react-s-alert";

import Summary from "../components/Summary";
import "../css/style.css";
import PreferencePanel from "./PreferencePanel";
import PIAnalaysis from "./PIAnalaysis";
import InputDataPI from "./InputDataPI";

class PersonalityInsights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      summaryData: {},
      personalityGraphData: [],
      consumerNeeds: [],
      consumerValues: [],
      consumerPreferences: []
    };
  }

  clearPreviousData = () => {
    this.setState({
      summaryData: {},
      personalityGraphData: [],
      consumerNeeds: [],
      consumerValues: [],
      consumerPreferences: []
    });
  };

  analyzeEnteredText = data => {
    this.setState({ fetching: true });
    let url = `${process.env.REACT_APP_PI_BASE_URL}/spacetime/pi_summary`;

    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "x-ibm-client-id": "f9bc90d5-d08c-4fd8-b78a-7b9f2d473dcb",
        "Content-Type": "application/json"
      },
      data: data,
      url
    };

    axios(options)
      .then(res => {
        if (res.data.msg) {
          Alert.error(res.data.msg, {
            position: "bottom-right",
            effect: "slide",
            timeout: 3000
          });
          this.setState({
            fetching: false
          });
        } else {
          let temp,
            pi_summary,
            pi_profile,
            summaryData = {},
            personalityGraphData = [],
            consumerNeeds = [],
            consumerValues = [],
            consumerPreferences = [];

          pi_summary = res.data.pi_summary;
          pi_profile = res.data.pi_profile;

          summaryData = {
            quality: pi_summary.quality,
            summaryText: pi_summary.text,
            wordCount: pi_summary.word_count,
            likely: pi_summary.consumption_preferences.likely,
            unlikely: pi_summary.consumption_preferences.unlikely
          };

          pi_profile.personality.forEach(content => {
            temp = {
              id: content.trait_id,
              name: content.name,
              percentile: content.percentile
            };
            personalityGraphData.push(temp);
          });

          pi_profile.needs.forEach(content => {
            temp = {
              id: content.trait_id,
              name: content.name,
              percentile: content.percentile
            };
            consumerNeeds.push(temp);
          });

          pi_profile.values.forEach(content => {
            temp = {
              id: content.trait_id,
              name: content.name,
              percentile: content.percentile
            };
            consumerValues.push(temp);
          });

          pi_profile.consumption_preferences.forEach(content => {
            temp = {
              id: content.consumption_preference_category_id,
              title: content.name,
              children: content.consumption_preferences
            };
            consumerPreferences.push(temp);
          });

          this.setState({
            fetching: false,
            summaryData,
            personalityGraphData,
            consumerNeeds,
            consumerValues,
            consumerPreferences
          });
        }
      })
      .catch(err => {
        this.setState({
          fetching: false
        });
        Alert.error("Sorry...Something went wrong! Please Try Again! ", {
          position: "bottom-right",
          effect: "slide",
          timeout: 3000
        });
      });
  };

  //New updated props from header.js to toggle settings and menu view
  componentWillReceiveProps = newProps => {
    this.setState({
      setactive: newProps.settingsActive
    });
  };

  render() {
    return (
      <div>
        <InputDataPI
          clearPreviousData={this.clearPreviousData}
          loader={this.state.fetching}
          analyzeEnteredText={this.analyzeEnteredText}
        />
        {Object.keys(this.state.summaryData).length ? (
          <Summary summaryData={this.state.summaryData} />
        ) : (
          ""
        )}

        {this.state.personalityGraphData.length &&
        this.state.consumerNeeds.length &&
        this.state.consumerValues.length ? (
          <div>
            <PIAnalaysis
              headings={["Personality", "Consumer Needs", "Values"]}
              personalityGraphData={this.state.personalityGraphData}
              consumerNeeds={this.state.consumerNeeds}
              consumerValues={this.state.consumerValues}
            />
          </div>
        ) : (
          <div />
        )}
        {this.state.consumerPreferences.length ? (
          <div style={{ marginTop: "50px" }}>
            <PreferencePanel
              consumerPreferences={this.state.consumerPreferences}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default PersonalityInsights;
