/* eslint-disable */
// Author: R Rahul, c706
import React, { Component } from "react";
import axios from "axios";
import { ReactMic } from "react-mic";
import PerfectScrollbar from "react-perfect-scrollbar";
import { SyncLoader } from "react-spinners";
import renderHTML from "react-render-html";
import Alert from "react-s-alert";

import baseURL from "./BaseURL";
import agentIcon from "../images/temp/Watson-logo.png";

import "react-perfect-scrollbar/dist/css/styles.css";
import Wrapper from "./Wrapper";

class ChatScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audioMode: this.props.audioMode,
      audioToPlay: null,
      contextId: null,
      engagementTracker: this.props.engagementTracker,
      id: 1,
      messages: [],
      newAgentIcon: this.props.newAgentIcon ? this.props.newAgentIcon : "",
      record: false,
      selectedAssistant: this.props.selectedAssistant,
      session_id: null,
      value: "" // Input query
    };
  }

  // This function is used to automatically scroll the chat window as new messages are added
  scrollToBottom = () => {
    if (this.refs.messagesEnd) {
      this.refs.messagesEnd._container.scrollTop = this.refs.scrollArea.clientHeight;
    }
  };

  // When component gets updated each time, scrollToBottom() is called for autoscroll to bottom
  componentDidUpdate() {
    this.scrollToBottom();
  }

  // Engagement Tracker toggle button property change from parent updation to state
  componentWillReceiveProps = newProps => {
    this.setState({
      audioMode: newProps.audioMode,
      engagementTracker: newProps.engagementTracker,
      selectedAssistant: newProps.selectedAssistant
    });
    this.props.selectedAssistant !== newProps.selectedAssistant &&
      this.clearChat(newProps.selectedAssistant);
  };

  // Input query is being stored to state
  handleChange = event => {
    this.setState({ value: event.target.value });
  };

  //Function watching for enter key to be pressed so as to process input query
  watchSubmitting = (event, recording) => {
    if (recording || event.key === "Enter") {
      if (event) event.preventDefault();

      let messages = [...this.state.messages];
      let searchText = this.state.value;

      // For inputting to state if search query is not empty
      if (this.state.value) {
        messages.push({
          id: this.state.id,
          from: "user",
          text: this.state.value
        });
        messages.forEach((message, index) => {
          if (message.from === "agent") messages[index].optionButtons = [];
        });
        this.setState({
          messages,
          value: "",
          id: this.state.id + 1,
          loading: true,
          audioToPlay: null
        });

        // Posting to API, the values in search query, to get response from agent
        const bearer =
          "Bearer " +
          JSON.parse(localStorage.getItem("userAccessToken")).accessToken;
        axios
          .post(`${baseURL}/api/conversationclick`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: bearer
            },

            session_id: this.state.session_id,
            credentials: "include",
            audioMode: this.state.audioMode,
            context: { id: this.state.contextId },
            natural_language_query: searchText,
            tone: this.state.engagementTracker,
            workspace: "CX",
            workspace_id: this.state.selectedAssistant
          })
          .then(res => {
            let apiResponseHolder = res.data.result;

            let temporaryMsgHolder = [...this.state.messages];

            this.setState({ loading: false });

            // Mapping response from agent to a temporary variable
            apiResponseHolder.response.output.generic.map(element => {
              return element.response_type === "image"
                ? temporaryMsgHolder.push({
                    id: Math.random(new Date()),
                    from: "agent",
                    image: element.source,
                    optionButtons: element.options ? element.options : []
                  })
                : temporaryMsgHolder.push({
                    id: Math.random(new Date()),
                    from: "agent",
                    text: element.text ? element.text : element.title,
                    optionButtons: element.options ? element.options : []
                  });
            });

            // Setting the message state with new updated value
            this.setState({
              messages: temporaryMsgHolder,
              audioToPlay: this.state.audioMode ? apiResponseHolder.data : null
            });

            // If the engagementTracker is turned on, tone is analyzed and displayed along with the message. "userMsgTones" variable has the tones of the last message from the user. If there is no tone from API, empty array is returned. If there is only one tone, an array with the tone as string is returned. If there are more than one tones, they are returned in a way that they are seperated using commas(,). Last value must not contain comma(,) , that is why length-1 of the array is taken.

            if (this.props.engagementTracker) {
              const userMsgTones = apiResponseHolder.toneAnalysis
                .utterances_tone[0].tones.length
                ? apiResponseHolder.toneAnalysis.utterances_tone[0].tones.map(
                    (tone, index) => {
                      return apiResponseHolder.toneAnalysis.utterances_tone[0]
                        .tones.length -
                        1 !==
                        index
                        ? tone.tone_name + ", "
                        : tone.tone_name;
                    }
                  )
                : [];

              // User message list is taken. userMessages variable consist of only the list of messages sent from user.
              const userMessages = this.state.messages.filter(
                message => message.from === "user"
              );
              const lastUserMessage = userMessages[userMessages.length - 1]; //Last message from the user

              lastUserMessage.tones = userMsgTones; //Assigning the tone to the last user message

              // Agent communication starts here!
              const agentMessages = this.state.messages.filter(
                message => message.from === "agent"
              );

              // This loop gets the tone inserted along with the message array in the state for agent's communication

              for (
                var i = 1;
                i < apiResponseHolder.toneAnalysis.utterances_tone.length;
                i++
              )
                for (var j = 0; j < agentMessages.length; j++)
                  // Checking for equality between Agent message and the message with tone from API
                  if (
                    apiResponseHolder.toneAnalysis.utterances_tone[i]
                      .utterance_text === agentMessages[j].text
                  ) {
                    agentMessages[j].tones = apiResponseHolder.toneAnalysis
                      .utterances_tone[i].tones.length
                      ? apiResponseHolder.toneAnalysis.utterances_tone[
                          i
                        ].tones.map((tone, index) => {
                          return apiResponseHolder.toneAnalysis.utterances_tone[
                            i
                          ].tones.length -
                            1 !==
                            index
                            ? tone.tone_name + ", "
                            : tone.tone_name;
                        })
                      : "None";
                  }
              this.forceUpdate(); //Since reference is passed in above step, state gets the updated value automatically, even without setting state. But since state is not updated, foceupdate is being used to update the state
            }
            //End of engagement tracker handling
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      }
    }
  };

  currentTime = () => {
    var today = new Date();

    var hour = today.getHours();
    var minute = today.getMinutes();
    var status = "am";

    // if greater than 12 hours, reduce to 12 hour format and insert pm
    if (hour > 12) {
      hour = hour - 12;
      status = "pm";
    }
    // If time is less than 10, add 0 as prefix  so as to show time 05:05pm rather than 5:5pm
    if (minute < 10) {
      minute = "0" + minute;
    }
    if (hour < 10) {
      hour = "0" + hour;
    }
    return hour + ":" + minute + status;
  };

  toggleRecording = () => {
    this.props.menuActive && this.props.menuClicked();
    this.props.settingsActive && this.props.settingsClicked();
    this.setState({
      record: !this.state.record
    });
  };

  onStop = recordedBlob => {
    if (recordedBlob.blob.size / 1024 / 1024 > 2) {
      Alert.error("File size exceeded", {
        position: "bottom-right",
        effect: "slide",
        timeout: 3000
      });
      return;
    }
    var fileName = recordedBlob.blobURL.split("/");
    this.uploadVoiceSearchData({
      blob: recordedBlob.blob,
      audioName: fileName
    });
  };
  uploadVoiceSearchData = data => {
    const formData = new FormData();
    formData.append("audio", data.blob, data.audioName[3]);

    axios
      .post(`${baseURL}/api/sta`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(res => {
        const recording = true;
        this.setState({ value: res.data.transcription });
        if (!res.data.transcription) {
          Alert.error("Unable to detect speech", {
            position: "bottom-right",
            effect: "slide",
            timeout: 3000
          });
        } else this.watchSubmitting(null, recording);
      })
      .catch(() => {});
  };

  clearChat = newAssistant => {
    this.setState({
      messages: [],
      session_id: null,
      loading: newAssistant !== "" && true,
      audioToPlay: null,
      contextId: Math.random()
    });
    if (newAssistant !== "") {
      const bearer =
        "Bearer " +
        JSON.parse(localStorage.getItem("userAccessToken")).accessToken;
      axios
        .post(`${baseURL}/api/conversationclick`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: bearer
          },

          session_id: null,
          audioMode: this.state.audioMode,
          collection_id: "f8b8e9df-85b9-4636-a9f9-063b737a7ad0",
          context: {},
          natural_language_query: "",
          tone: this.state.engagementTracker,
          workspace: "CX",
          workspace_id: newAssistant
        })
        .then(res => {
          let apiResponseHolder = res.data.result.uniquevalueResponse;
          let temporaryMsgHolder = [];

          this.setState({
            loading: false,
            session_id: res.data.result.response.session_id
          });

          //Mapping the message to state from response
          apiResponseHolder.map(element => {
            return element.type === "image"
              ? temporaryMsgHolder.push({
                  id: element.randomnumber,
                  from: "agent",
                  image: element.source
                })
              : temporaryMsgHolder.push({
                  id: element.randomnumber,
                  from: "agent",
                  text: element.text
                });
          });
          //Setting the state with message from response
          this.setState({
            messages: temporaryMsgHolder,
            audioToPlay: this.state.audioMode ? res.data.result.data : null
          });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  render() {
    return (
      <div className="text-tab">
        <div className="chat">
          <div className="chat-wrp">
            <div className="chat-top">
              {/* Scrolling for chat  */}
              <PerfectScrollbar ref="messagesEnd">
                <div className="chat-history" ref="scrollArea">
                  <ul>
                    <li>
                      {this.state.messages.map(message => {
                        return (
                          // If the response is text
                          ((message.text ||
                            (message.optionButtons &&
                              message.optionButtons.length)) && (
                            <div key={message.id} className="message-data">
                              {message.from === "agent" ? (
                                <div className="clearfix">
                                  <div className="message-data-api">
                                    {message.text ? (
                                      <Wrapper>
                                        <img
                                          src={
                                            this.props.newAgentIcon
                                              ? this.props.newAgentIcon
                                              : agentIcon
                                          }
                                        />
                                        <span className="msg-time">
                                          {this.currentTime()}
                                        </span>
                                        {renderHTML(message.text)}
                                      </Wrapper>
                                    ) : (
                                      ""
                                    )}

                                    {/* If there are any buttons to show as response */}
                                    {message.optionButtons &&
                                      message.optionButtons.length > 0 &&
                                      message.optionButtons.map(element => (
                                        <div
                                          className="totalbtn-sect"
                                          key={element.value.input.text}
                                        >
                                          <button
                                            className="btn btn-new-style"
                                            onClick={() => {
                                              this.setState(
                                                {
                                                  value:
                                                    element.value.input.text
                                                },
                                                () => {
                                                  this.watchSubmitting(
                                                    null,
                                                    true
                                                  );
                                                }
                                              );
                                            }}
                                          >
                                            {element.label}
                                          </button>
                                        </div>
                                      ))}
                                    {message.tones && message.tones.length ? (
                                      <span className="chatstatus">
                                        {message.tones}
                                      </span>
                                    ) : (
                                      message.tones && (
                                        <span className="chatstatus">None</span>
                                      )
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div className="clearfix">
                                  <div className="message-data-user">
                                    <small>{this.currentTime()}</small>
                                    <p>{message.text}</p>
                                    {message.tones && message.tones.length ? (
                                      <span className="chatstatus">
                                        {message.tones}
                                      </span>
                                    ) : (
                                      message.tones && (
                                        <span className="chatstatus">None</span>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          )) ||
                          // If the response is an image
                          (message.image && (
                            <div key={message.id} className="message-data">
                              <div className="clearfix">
                                <div className="message-data-api">
                                  <img
                                    src={
                                      this.props.newAgentIcon
                                        ? this.props.newAgentIcon
                                        : agentIcon
                                    }
                                  />
                                  <small>{this.currentTime()}</small>
                                  <div
                                    style={{
                                      maxWidth: "450px",
                                      height: "auto"
                                    }}
                                  >
                                    <img
                                      width="100%"
                                      height="100%"
                                      src={message.image}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        );
                      })}
                    </li>
                  </ul>
                </div>
              </PerfectScrollbar>
              {/* End of scroll bar */}
            </div>

            {/* Loading component => To be showed till response from API is recieved */}
            {this.state.loading && (
              <div className="text-center" style={{ marginTop: "-75px" }}>
                <SyncLoader
                  color={"#785ef0"}
                  size={8}
                  loading={this.props.loader}
                />
              </div>
            )}
            <div className="chat-message clearfix">
              <form onSubmit={this.handleSubmit}>
                <input
                  onClick={() => {
                    this.props.menuActive && this.props.menuClicked();
                    this.props.settingsActive && this.props.settingsClicked();
                  }}
                  disabled={this.state.selectedAssistant.length === 0}
                  type="text"
                  name="message-to-send"
                  className="form-control"
                  value={this.state.value}
                  onKeyPress={event => this.watchSubmitting(event)} //To listen whether enter key is pressed
                  onChange={event => this.handleChange(event)}
                  id="message-to-send"
                  placeholder="Type your message"
                  required
                />
                <button
                  type="button"
                  disabled={this.state.selectedAssistant.length === 0}
                  onClick={this.toggleRecording}
                  className={
                    this.state.record
                      ? "btn-record pull-left active"
                      : "btn-record pull-left"
                  }
                >
                  <i className="fa fa-microphone" aria-hidden="true" />
                </button>
                <span className="hide">
                  <ReactMic
                    record={this.state.record}
                    className="sound-wave"
                    onStop={this.onStop}
                  />
                </span>
              </form>
            </div>
          </div>
        </div>
        {this.state.audioToPlay && this.state.audioToPlay.length > 0 && (
          <audio id="audioToPlay" autoPlay>
            <source src={this.state.audioToPlay} type="audio/wav" />
          </audio>
        )}
      </div>
    );
  }
}
export default ChatScreen;
