/* eslint-disable */
// Author: R Rahul, c706
import React, { Component } from "react";
import axios from "axios";
import { SyncLoader } from "react-spinners";
import renderHTML from "react-render-html";
import { ReactMic } from "react-mic";
import Alert from "react-s-alert";

import baseURL from "./BaseURL";
import Graph from "./Graph";
import workspace_id from "./Workspace";
import Wrapper from "./Wrapper";

import homelogo from "../images/temp/StIcon500px (1).png";
import link from "../images/temp/link.png";
import settingsbutton from "../images/temp/Settings-Icon.svg";

var expanded = false;
let linksArray, resultArray;
const createV4SelectableForceDirectedGraph =
  window.createV4SelectableForceDirectedGraph;
const d3 = window.d3;

class Discovery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      collection: [],
      contentToShow: false,
      contextId: null,
      documentView: "html",
      filterValues: [],
      graphLoading: false,
      id: 1,
      loading: false,
      menuActive: this.props.menuActive,
      record: false,
      searchQuery: "",
      selectedCollectionItem: "",
      setactive: this.props.settingsActive,
      totalPage: 1,
      typesGraph: [],
      view: "DV"
    };
  }

  componentDidMount() {
    const bearer =
      "Bearer " +
      JSON.parse(localStorage.getItem("userAccessToken")).accessToken;

    this.setState({ contextId: Math.random() });
    axios
      .get(`${baseURL}/api/listcollection`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer
        },
        credentials: "include"
      })

      .then(res => {
        this.setState({
          collection: res.data.collections,
          selectedCollectionItem: res.data.collections[0].collection_id
        });
      })
      .catch(() => {});
  }

  // Used to handle the settings button click
  settingClick = () => {
    this.props.settingsClicked(); //Call back to parent component(Header.js)
  };

  //New updated props from header.js to toggle settings and menu view
  componentWillReceiveProps = newProps => {
    this.setState({
      setactive: newProps.settingsActive,
      menuActive: newProps.menuActive
    });
  };

  // Changing the drop down list of collection in side bar
  collectionChange = collection_id => {
    this.clearAllData();
    this.setState({
      selectedCollectionItem: collection_id
    });
  };

  // Saving value of search query in the state
  handleSearchQuery = value => {
    this.setState({ searchQuery: value });
  };

  //Function watching for enter key to be pressed so as to process input query
  watchSubmitting = (event, recording) => {
    if (recording || event.key === "Enter") {
      if (event) event.preventDefault();
      // Posting to API, the values in search query, to get response from agent
      this.setState({
        loading: true,
        contentToShow: [],
        knowledgeGraph: undefined,
        activePage: 1,
        view: "DV"
      });
      const bearer =
        "Bearer " +
        JSON.parse(localStorage.getItem("userAccessToken")).accessToken;

      axios
        .post(`${baseURL}/api/discovery`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: bearer
          },

          credentials: "include",
          audioMode: false,
          context: { id: this.state.contextId },
          collection_id: this.state.selectedCollectionItem,
          natural_language_query: this.state.searchQuery,
          tone: this.state.engagementTracker,
          workspace: "MS",
          workspace_id: workspace_id
        })
        .then(res => {
          let contentToShow = [];

          this.setState(
            {
              loading: false,
              passages: res.data.result.result.passages,
              searchResults: res.data.result.result.results
            },
            () => {
              for (var i = 0; i < this.state.passages.length; i++)
                for (var j = 0; j < this.state.searchResults.length; j++)
                  if (
                    this.state.passages[i].document_id ===
                    this.state.searchResults[j].id
                  ) {
                    contentToShow.push({
                      id: new Date().getTime().toString() + Math.random(),
                      document_id: this.state.passages[i].document_id,
                      passageTitle: this.state.searchResults[j].metadata.title,
                      passageText: this.state.passages[i].passage_text,
                      start_offset: this.state.passages[i].start_offset,
                      end_offset: this.state.passages[i].end_offset,
                      url: this.state.searchResults[j].metadata.url,
                      html: this.state.searchResults[j].html,
                      liked: false
                    });
                  }
              this.setState(
                {
                  contentToShow
                },
                () => {
                  this.setState(
                    {
                      totalPage: this.state.contentToShow.length,
                      currentActiveDoc: this.state.contentToShow.length
                        ? this.state.contentToShow[0].document_id
                        : null
                    },
                    () => this.highlightText()
                  );
                }
              );
            }
          );
        })
        .catch(err => {
          this.setState({ loading: false });
        });
    }
  };

  filterGraph(value) {
    const nodesArray = JSON.parse(localStorage.getItem("nodesArray"));

    var x = document.getElementsByClassName("filterGraph");
    var j = 0;
    let selectedFilter = [];

    for (var i = 0; i < x.length; i++) {
      if (x[i].checked) {
        j++;
        selectedFilter.push(x[i].value);
      }
    }

    selectedFilter.push("Document");
    if (j === x.length) {
      linksArray = nodesArray.links;
      resultArray = nodesArray.nodes;
    } else if (j === 0) {
      linksArray = nodesArray.links.filter(function(ln) {
        return ln.target.includes("Document");
      });

      const filterArray1 = nodesArray.nodes.filter(function(ln) {
        return "Document" === ln.type;
      });
      resultArray = filterArray1;
    } else {
      linksArray = nodesArray.links.filter(function(ln) {
        const res = selectedFilter.filter(item => ln.target.includes(item));
        return res.length > 0;
      });
      const filterArray = nodesArray.nodes.filter(function(ln) {
        return selectedFilter.includes(ln.type);
      });
      resultArray = filterArray;
    }

    const nodes = {
      links: linksArray,
      nodes: resultArray
    };
    const svg = d3.select("#d3_selectable_force_directed_graph");
    createV4SelectableForceDirectedGraph(svg, nodes, selectedFilter);
  }

  dropDownClick() {
    var checkboxes = document.getElementById("checkboxes");
    if (!expanded) {
      checkboxes.style.display = "block";
      expanded = true;
    } else {
      checkboxes.style.display = "none";
      expanded = false;
    }
  }

  toggleRecording = () => {
    this.props.menuActive && this.props.menuClicked();
    this.props.settingsActive && this.props.settingsClicked();
    this.setState({
      record: !this.state.record
    });
  };

  onStop = recordedBlob => {
    if (recordedBlob.blob.size / 1024 / 1024 > 2) {
      Alert.error("File size exceeded", {
        position: "bottom-right",
        effect: "slide",
        timeout: 3000
      });
      return;
    }
    var fileName = recordedBlob.blobURL.split("/");
    this.uploadVoiceSearchData({
      blob: recordedBlob.blob,
      audioName: fileName
    });
  };

  uploadVoiceSearchData = data => {
    const formData = new FormData();
    formData.append("audio", data.blob, data.audioName[3]);

    axios
      .post(`${baseURL}/api/sta`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(res => {
        const recording = true;
        this.setState({ searchQuery: res.data.transcription });
        if (!res.data.transcription) {
          Alert.error("Unable to detect speech", {
            position: "bottom-right",
            effect: "slide",
            timeout: 3000
          });
        } else this.watchSubmitting(null, recording);
      })
      .catch(() => {});
  };

  clearAllData = () => {
    this.state.contentToShow === false
      ? this.setState({ contentToShow: false, searchQuery: "" })
      : this.setState({
          contentToShow: undefined,
          knowledgeGraph: [],
          searchQuery: "",
          activePage: 1
        });
  };

  viewGraph = () => {
    this.setState({
      view: "KG",
      graphLoading: true
    });
    axios
      .post(`${baseURL}/api/knowledgegraph`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },

        collection_id: this.state.selectedCollectionItem,
        natural_language_query: this.state.searchQuery
      })
      .then(respo => {
        const typesGraph = [];
        if (respo.data.result && respo.data.result.nodes) {
          this.setState(
            {
              knowledgeGraph: JSON.parse(JSON.stringify(respo.data.result)),
              graphLoading: false
            },
            () => {
              localStorage.setItem(
                "nodesArray",
                JSON.stringify(respo.data.result)
              );
            }
          );

          respo.data.result.nodes.forEach(item => {
            if (!typesGraph.includes(item.type) && item.type !== "Document") {
              typesGraph.push(item.type);
            }
          });
          this.setState({ typesGraph });
        }
      })
      .catch(err => {
        this.setState({
          graphLoading: false
        });
      });
  };
  likeDocument = id => {
    const bearer =
      "Bearer " +
      JSON.parse(localStorage.getItem("userAccessToken")).accessToken;

    axios.post(`${baseURL}/api/events`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer
      },

      credentials: "include"
    });
    const temporaryHolder = this.state.contentToShow;
    temporaryHolder.map((content, index) => {
      if (content.id === id) {
        temporaryHolder[index].liked = true;
      }
    });

    this.setState({ contentToShow: temporaryHolder });
  };

  // Next Button clicked in pagination
  nextPage = () => {
    this.state.activePage < this.state.totalPage &&
      this.setState(
        {
          activePage: this.state.activePage + 1
        },
        () => {
          this.setState(
            {
              currentActiveDoc: this.state.contentToShow[
                this.state.activePage - 1
              ].document_id
            },
            () => this.highlightText()
          );
        }
      );
  };

  // Previous Button clicked in pagination
  previousPage = () => {
    this.state.activePage > 1 &&
      this.setState(
        {
          activePage: this.state.activePage - 1
        },
        () => {
          this.setState(
            {
              currentActiveDoc: this.state.contentToShow[
                this.state.activePage - 1
              ].document_id
            },
            () => this.highlightText()
          );
        }
      );
  };

  highlightText = () => {
    this.state.contentToShow.map((content, index) => {
      if (this.state.activePage === index + 1) {
        const htmlTextToColour = content.passageText.replace("no title", "");

        var markInstance = new Mark(document.querySelector(".context"));
        var keyword = htmlTextToColour;

        var options = {
          acrossElements: true,
          debug: false,
          diacritics: true,
          separateWordSearch: false
        };

        markInstance.unmark({
          done: function() {
            markInstance.mark(keyword, options);
          }
        });
      }
    });
    document.querySelector("mark") &&
      document.querySelector("mark").scrollIntoView({ behavior: "smooth" });
  };

  // Show the active document
  showDocument = (result, index) => {
    this.setState(
      {
        currentActiveDoc: null,
        activePage: index,
        currentActiveDoc: result.document_id
      },
      () => this.highlightText()
    );
  };

  render() {
    return (
      <Wrapper>
        <div
          className={
            this.state.setactive
              ? "settingtbutton-img active"
              : "settingtbutton-img"
          }
          onClick={this.settingClick}
        >
          <img src={settingsbutton} alt="" />
        </div>
        <div
          className={
            this.state.setactive ? "st-settings active" : "st-settings"
          }
        >
          <div className={this.state.loader ? "settings-overlay" : ""}>
            <ul className="st-setting-sec">
              <li>
                <div>
                  <label>Collection</label>

                  <div className="select-drops">
                    <select
                      className="form-control"
                      onChange={e => this.collectionChange(e.target.value)}
                    >
                      {this.state.collection.map(value => (
                        <option
                          value={value.collection_id}
                          key={value.collection_id}
                        >
                          {value.name}
                        </option>
                      ))}
                    </select>
                    <i className="fa fa-caret-down" aria-hidden="true" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <section className="inner-sections">
          <div className="row">
            <div className="col-md-4">
              <div className="search-areas">
                <form>
                  <input
                    onClick={() => {
                      this.props.menuActive && this.props.menuClicked();
                      this.props.settingsActive && this.props.settingsClicked();
                    }}
                    type="text"
                    name="message-to-send"
                    className="form-control"
                    value={this.state.searchQuery}
                    id="message-to-send"
                    placeholder="Type your message"
                    required=""
                    onChange={event =>
                      this.handleSearchQuery(event.target.value)
                    }
                    onKeyPress={event => this.watchSubmitting(event)}
                  />
                  <button
                    type="button"
                    onClick={this.toggleRecording}
                    className={
                      this.state.record
                        ? "btn-record pull-left active"
                        : "btn-record pull-left"
                    }
                  >
                    <i className="fa fa-microphone" aria-hidden="true" />
                  </button>
                  <span className="hide">
                    <ReactMic
                      record={this.state.record}
                      className="sound-wave"
                      onStop={this.onStop}
                    />
                  </span>
                </form>

                {this.state.loading && (
                  <div className="text-center">
                    <SyncLoader
                      color={"#785ef0"}
                      size={8}
                      loading={this.props.loader}
                    />
                  </div>
                )}

                <div className="results-newsearch">
                  {this.state.contentToShow ? (
                    this.state.contentToShow.length ? (
                      <h2 className="links-ttl">
                        Results: {this.state.contentToShow.length}
                      </h2>
                    ) : null
                  ) : (
                    ""
                  )}

                  {this.state.contentToShow
                    ? this.state.contentToShow.length
                      ? this.state.contentToShow.map((result, index) => (
                          <div key={result.id} className="result-item">
                            <h3>
                              <a
                                href="#highlightText"
                                className=""
                                onClick={() =>
                                  this.showDocument(result, index + 1)
                                }
                              >
                                {result.passageTitle}
                              </a>
                            </h3>
                            <p>
                              {result.passageText.replace(/(<([^>]+)>)/gi, "")}
                            </p>
                            <a
                              className="readmore"
                              href="#highlightText"
                              onClick={() =>
                                this.showDocument(result, index + 1)
                              }
                            >
                              Read More &gt;
                            </a>
                          </div>
                        ))
                      : !this.state.loading && <p>No data Found</p>
                    : ""}
                </div>
              </div>
            </div>
            <div className="col-md-8">
              {this.state.contentToShow ? (
                this.state.contentToShow.length ? (
                  <div className="newtab-area">
                    <ul className="nav nav-tabs">
                      <li className={this.state.view === "DV" ? "active" : ""}>
                        <a
                          data-toggle="tab"
                          href="#highlightText"
                          onClick={() =>
                            this.setState({ view: "DV" }, () =>
                              this.highlightText()
                            )
                          }
                        >
                          Document View
                        </a>
                      </li>
                      <li className={this.state.view === "KG" ? "active" : ""}>
                        <a
                          data-toggle="tab"
                          href="#menu1"
                          onClick={() => this.viewGraph()}
                        >
                          Knowledge Graph
                        </a>
                      </li>
                      {this.state.view === "KG" && (
                        <li>
                          <div className="multiselect float-left">
                            <div
                              className="selectBox"
                              onClick={this.dropDownClick.bind(this, false)}
                            >
                              <select>
                                <option>Filter</option>
                              </select>
                              <div className="overSelect" />
                            </div>
                            <div id="checkboxes">
                              {this.state.typesGraph &&
                                this.state.typesGraph.length > 0 &&
                                this.state.typesGraph.map((entity, index) => {
                                  return (
                                    <label key={index}>
                                      <input
                                        type="checkbox"
                                        defaultChecked={true}
                                        className="filterGraph"
                                        value={entity}
                                        onClick={this.filterGraph.bind(
                                          this,
                                          entity
                                        )}
                                      />
                                      {entity}
                                    </label>
                                  );
                                })}
                            </div>
                          </div>
                        </li>
                      )}
                    </ul>

                    <div className="tab-content">
                      {this.state.view === "DV" ? (
                        this.state.contentToShow &&
                        this.state.contentToShow.map((content, index) => {
                          if (
                            content.document_id ===
                              this.state.currentActiveDoc &&
                            this.state.activePage - 1 === index
                          )
                            return (
                              <div
                                id="home"
                                key={content.id}
                                className="tab-pane fade in active"
                              >
                                <div
                                  className="row"
                                  style={{ padding: "0px 20px" }}
                                >
                                  <div className="col-sm-6">
                                    <h4 className="dis-caption">
                                      {content.passageTitle
                                        ? content.passageTitle
                                        : "No Title Available"}{" "}
                                      <i
                                        onClick={() => {
                                          this.likeDocument(content.id);
                                        }}
                                        style={
                                          content.liked
                                            ? {
                                                color: "#000",
                                                cursor: "pointer"
                                              }
                                            : { cursor: "pointer" }
                                        }
                                        className="fa fa-thumbs-up"
                                        aria-hidden="true"
                                      />
                                    </h4>
                                  </div>

                                  <div className="col-sm-6">
                                    <div className="innertabarea">
                                      <ul className="nav nav-tabs">
                                        <li
                                          className={
                                            this.state.documentView === "html"
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          <a
                                            data-toggle="tab"
                                            href="#highlightText"
                                            onClick={() =>
                                              this.setState(
                                                {
                                                  documentView: "html"
                                                },
                                                () => this.highlightText()
                                              )
                                            }
                                          >
                                            HTML
                                          </a>
                                        </li>
                                        <li
                                          className={
                                            this.state.documentView === "inline"
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          <a
                                            data-toggle="tab"
                                            href="#"
                                            onClick={() =>
                                              this.setState({
                                                documentView: "inline"
                                              })
                                            }
                                          >
                                            Document Inline
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href={content.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Document External{" "}
                                            <img src={link} alt="" />
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="tab-content tab-content-inner"
                                  style={{ padding: "0px 20px" }}
                                >
                                  <div
                                    id="html"
                                    className="tab-pane fade in active"
                                  >
                                    {this.state.documentView === "html" ? (
                                      this.state.currentActiveDoc ? (
                                        renderHTML(
                                          `<div className="context">${
                                            content.html
                                          }</div>`
                                        )
                                      ) : null
                                    ) : (
                                      <div className="iframe-space">
                                        <iframe
                                          title="documentView"
                                          src={content.url}
                                          width="100%"
                                          height="100%"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          return null;
                        })
                      ) : this.state.knowledgeGraph ? (
                        <span>
                          <div id="home" className="tab-pane fade in active">
                            <Graph searchData={this.state.knowledgeGraph} />
                          </div>
                        </span>
                      ) : (
                        this.state.graphLoading && (
                          <div
                            className="text-center"
                            style={{ marginTop: "75px" }}
                          >
                            <SyncLoader
                              color={"#785ef0"}
                              size={8}
                              loading={this.props.loader}
                            />
                          </div>
                        )
                      )}
                      {this.state.contentToShow &&
                      this.state.view !== "KG" &&
                      this.state.contentToShow.length ? (
                        <div className="custom-pagination">
                          <span className="result-page">Result</span>
                          <span className="values-page">
                            {this.state.activePage}/{this.state.totalPage}
                          </span>
                          <span className="pre-page">
                            <a href="#highlightText">
                              <i
                                className="fa fa-angle-left"
                                aria-hidden="true"
                                onClick={() => this.previousPage()}
                              />
                            </a>
                          </span>
                          <span className="next-page">
                            <a href="#highlightText">
                              <i
                                className="fa fa-angle-right"
                                aria-hidden="true"
                                onClick={() => this.nextPage()}
                              />
                            </a>
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div className="newtab-area">
                    <div className="homelogo" style={{ height: "50vh" }}>
                      <div className="logospace">
                        <img src={homelogo} alt="" />
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div className="newtab-area">
                  <div className="homelogo" style={{ height: "50vh" }}>
                    <div className="logospace">
                      <img src={homelogo} alt="" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </Wrapper>
    );
  }
}
export default Discovery;
