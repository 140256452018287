import React, { Component } from "react";
import VisualRecognitionLogo from "../images/temp/couple.jpg";
import axios from "axios";
import { SyncLoader } from "react-spinners";
import uuid from "uuid";
import Alert from "react-s-alert";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import baseurl from "./BaseURL";

class VisualRecognition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      type: "file",
      data3: {},
      file_url: VisualRecognitionLogo,
      loader: false,
      hierarchy: false
    };
    this.imageHandler = this.imageHandler.bind(this);
  }

  imageHandler(e) {
    var file = e.target.files[0];
    if (file.type.indexOf("image") === -1) {
      Alert.error("Invalid file format", {
        position: "bottom-right",
        effect: "slide",
        timeout: 3000
      });
      return;
    }
    if (file.size / 1024 / 1024 > 5) {
      Alert.error("File size exceeded", {
        position: "bottom-right",
        effect: "flip",
        timeout: 3000
      });
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function(e) {
      this.setState({
        file_url: [reader.result]
      });
    }.bind(this);
    this.fileUpload(file, this.state.type);
  }

  fileUpload(file, type) {
    this.setState({ loader: true, hierarchy: false });
    const formData = new FormData();
    formData.append("image", file);
    formData.append("type", "file");
    axios
      .post(`${baseurl}/api/vr`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        this.setState({ data3: response.data, loader: false });
      });
  }

  render() {
    return (
      <div>
        <div className="image-upload">
          <img src={this.state.file_url} alt="visualrecoimage" />
          <div className="photo-upload">
            <span className="sprite-photo" />
            <input
              type="file"
              className="upload-ic"
              name="pic"
              accept="image/*"
              onChange={this.imageHandler}
            />
          </div>
        </div>

        {this.state.loader ? (
          <div className="text-center" style={{ marginTop: "60px" }}>
            <SyncLoader color={"#785ef0"} loading={this.state.loading} />
          </div>
        ) : (
          <div>
            <section className="inner-sections">
              {this.state.data3.general ? (
                <div className="analyze-box">
                  <h5>Classes</h5>

                  <div>
                    <div className="hr-div clearfix">
                      <hr />
                    </div>
                    <div className="row">
                      {this.state.data3.general[0].classifiers[0].classes.map(
                        c => (
                          <div className="col-md-4 status-box" key={uuid.v4()}>
                            <div className="clearfix">
                              <span className="anlyzetext">{c.class}</span>
                            </div>
                            <div className="statussect">
                              <div className="statusbar">
                                {Math.floor(c.score * 100) > 0 && (
                                  <span
                                    className="statusbar-fill"
                                    style={{
                                      width: Math.floor(c.score * 100) + "%"
                                    }}
                                  />
                                )}
                                <span
                                  className="anlyzepercntg"
                                  style={
                                    Math.floor(c.score * 100) === 0
                                      ? { paddingLeft: 0 }
                                      : {
                                          left: Math.floor(c.score * 100) + "%"
                                        }
                                  }
                                >
                                  {Math.floor(c.score * 100) + "%"}
                                </span>
                                {c.type_hierarchy && !this.state.hierarchy
                                  ? this.setState({ hierarchy: true })
                                  : null}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div />
              )}

              {this.state.data3.face_detection ? (
                this.state.data3.face_detection[0].faces &&
                this.state.data3.face_detection[0].faces.length > 0 && (
                  <div className="analyze-box">
                    <h5>Faces</h5>
                    <div className="hr-div clearfix">
                      <hr />
                    </div>
                    <div className="row">
                      {this.state.data3.face_detection[0].faces.map(c => (
                        <div key={uuid.v4()}>
                          <div className="col-md-4 status-box">
                            <div className="clearfix">
                              <span className="anlyzetext">
                                Age {c.age.min}-{c.age.max}
                              </span>
                            </div>
                            <div className="statussect">
                              <div className="statusbar">
                                {Math.floor(c.age.score * 100) > 0 && (
                                  <span
                                    className="statusbar-fill"
                                    style={{
                                      width: Math.floor(c.age.score * 100) + "%"
                                    }}
                                  />
                                )}
                                <span
                                  className="anlyzepercntg"
                                  style={
                                    Math.floor(c.age.score * 100) === 0
                                      ? { paddingLeft: 0 }
                                      : {
                                          left:
                                            Math.floor(c.age.score * 100) + "%"
                                        }
                                  }
                                >
                                  {Math.floor(c.age.score * 100) + "%"}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 status-box">
                            <div className="clearfix">
                              <span className="anlyzetext">
                                {c.gender.gender[0] +
                                  c.gender.gender.toLowerCase().slice(1)}
                              </span>
                            </div>
                            <div className="statussect">
                              <div className="statusbar">
                                {Math.floor(c.gender.score * 100) > 0 && (
                                  <span
                                    className="statusbar-fill"
                                    style={{
                                      width:
                                        Math.floor(c.gender.score * 100) + "%"
                                    }}
                                  />
                                )}
                                <span
                                  className="anlyzepercntg"
                                  style={
                                    Math.floor(c.gender.score * 100) === 0
                                      ? { paddingLeft: 0 }
                                      : {
                                          left:
                                            Math.floor(c.gender.score * 100) +
                                            "%"
                                        }
                                  }
                                >
                                  {Math.floor(c.gender.score * 100) + "%"}
                                </span>
                              </div>
                            </div>
                          </div>
                          {c.identity && (
                            <div className="col-md-4 status-box">
                              <div className="clearfix">
                                <span className="anlyzetext">
                                  {c.identity.name}
                                </span>
                              </div>
                              <div className="statussect">
                                <div className="statusbar">
                                  {Math.floor(c.identity.score * 100) > 0 && (
                                    <span
                                      className="statusbar-fill"
                                      style={{
                                        width:
                                          Math.floor(c.identity.score * 100) +
                                          "%"
                                      }}
                                    />
                                  )}
                                  <span
                                    className="anlyzepercntg"
                                    style={
                                      Math.floor(c.identity.score * 100) === 0
                                        ? { paddingLeft: 0 }
                                        : {
                                            left:
                                              Math.floor(
                                                c.identity.score * 100
                                              ) + "%"
                                          }
                                    }
                                  >
                                    {Math.floor(c.identity.score * 100) + "%"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )
              ) : (
                <div />
              )}

              {this.state.data3.general && this.state.hierarchy && (
                <div className="analyze-box">
                  <h5>Type Hierarchy</h5>
                  <div className="hr-div clearfix">
                    <hr />
                  </div>
                  <div className="row">
                    {this.state.data3.general[0].classifiers[0].classes.map(
                      c =>
                        c.type_hierarchy && (
                          <div className="col-md-4 status-box" key={uuid.v4()}>
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            />
                            <span>{c.type_hierarchy}</span>
                          </div>
                        )
                    )}
                  </div>
                </div>
              )}
            </section>
          </div>
        )}
      </div>
    );
  }
}

export default VisualRecognition;
