import React from "react";
import { PanelGroup, Panel } from "react-bootstrap";

const formatPreference = (preference, score) => {
  let withoutLikely = preference.replace("Likely to ", "");

  if (score === 0) {
    return `${withoutLikely.charAt(0).toUpperCase()}${withoutLikely.slice(
      1
    )}-Unlikely`;
  } else if (score === 1) {
    return `${withoutLikely.charAt(0).toUpperCase()}${withoutLikely.slice(
      1
    )}-Likely`;
  } else {
    return `${withoutLikely.charAt(0).toUpperCase()}${withoutLikely.slice(
      1
    )}-Neutral`;
  }
};

const PreferencePanel = props => {
  return (
    <div>
      {props.consumerPreferences.map(preference => (
        <div key={preference.id} className="col-md-4">
          <div className="analyze-box">
            <PanelGroup
              accordion
              id="accordion-uncontrolled-example"
              defaultActiveKey="1"
            >
              <Panel className="insight-box">
                <Panel.Heading>
                  <Panel.Title toggle>
                    <b className="collpse-sect">{preference.title}</b>
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  {preference.children.map(child => (
                    <div
                      key={child.consumption_preference_id}
                      className="insight-box-sub"
                    >
                      <div className="clearfix">
                        <span className="anlyzetext">
                          {formatPreference(child.name, child.score)}
                        </span>
                      </div>
                    </div>
                  ))}
                </Panel.Body>
              </Panel>
            </PanelGroup>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PreferencePanel;
