import React, { Component } from "react";
import SpiderGraph from "../components/SpiderGraph";
import "../css/style.css";

export default class AnalyzeBox extends Component {
  render() {
    const { data, data4 } = this.props;

    return (
      <div>
        <section className="inner-sections">
          <div className="row">
            <SpiderGraph data={this.props.data} />
            <div className="col-md-3">
              <div className="analyze-box lan-ht">
                <h5>Sentiment</h5>
                <div className="analyze-box entities">
                  <div className="hr-div clearfix" />
                  <div className="status-box">
                    <div className="clearfix">
                      <span className="anlyzetext">
                        {data.sentiment
                          ? data.sentiment.label[0].toUpperCase() +
                            data.sentiment.label.slice(1)
                          : null}
                      </span>
                    </div>
                    <div className="statussect">
                      <div className="statusbar">
                        {data.sentiment &&
                          Math.floor(data.sentiment.score * 100) > 0 && (
                            <span
                              className="statusbar-fill"
                              style={{
                                width:
                                  (data.sentiment
                                    ? Math.floor(data.sentiment.score * 100)
                                    : null) + "%"
                              }}
                            />
                          )}
                        <span
                          className="anlyzepercntg"
                          style={
                            data.sentiment &&
                            Math.floor(data.sentiment.score * 100) <= 0
                              ? { paddingLeft: 0 }
                              : {
                                  left:
                                    Math.abs(
                                      Math.floor(data.sentiment.score * 100)
                                    ) + "%"
                                }
                          }
                        >
                          {data.sentiment
                            ? Math.floor(data.sentiment.score * 100)
                            : null}
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="analyze-box lan-ht">
                <h5>Natural Language Classifier</h5>
                <div className="hr-div clearfix" />
                <div className="analyze-box entities">
                  {data4.error ? (
                    <span className="anlyzetext text-danger">
                      {data4.error.description}
                    </span>
                  ) : (
                    <div>
                      <div className="status-box">
                        <div className="clearfix">
                          <span className="anlyzetext">
                            {data4.response
                              ? data4.response.classes[0][
                                  "class_name"
                                ][0].toUpperCase() +
                                data4.response.classes[0]["class_name"].slice(1)
                              : null}
                          </span>
                        </div>
                        <div className="statussect">
                          <div className="statusbar">
                            {data4.response &&
                              Math.floor(
                                data4.response.classes[0]["confidence"] * 100
                              ) > 0 && (
                                <span
                                  className="statusbar-fill"
                                  style={{
                                    width:
                                      (data4.response
                                        ? Math.abs(
                                            Math.floor(
                                              data4.response.classes[0][
                                                "confidence"
                                              ] * 100
                                            )
                                          )
                                        : null) + "%"
                                  }}
                                />
                              )}
                            <span
                              className="anlyzepercntg"
                              style={
                                data4.response &&
                                Math.floor(
                                  data4.response.classes[0]["confidence"] * 100
                                ) === 0
                                  ? { paddingLeft: 0 }
                                  : {
                                      left:
                                        Math.floor(
                                          data4.response &&
                                            data4.response.classes[0][
                                              "confidence"
                                            ] * 100
                                        ) + "%"
                                    }
                              }
                            >
                              {data4.response
                                ? Math.floor(
                                    data4.response.classes[0]["confidence"] *
                                      100
                                  )
                                : null}
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="status-box">
                        <div className="clearfix">
                          <span className="anlyzetext">
                            {data4.response
                              ? data4.response.classes[1][
                                  "class_name"
                                ][0].toUpperCase() +
                                data4.response.classes[1]["class_name"].slice(1)
                              : null}
                          </span>
                        </div>
                        <div className="statussect">
                          <div className="statusbar">
                            {data4.response &&
                              Math.floor(
                                data4.response.classes[1]["confidence"] * 100
                              ) > 0 && (
                                <span
                                  className="statusbar-fill"
                                  style={{
                                    width:
                                      (data4.response
                                        ? Math.abs(
                                            Math.floor(
                                              data4.response.classes[1][
                                                "confidence"
                                              ] * 100
                                            )
                                          )
                                        : null) + "%"
                                  }}
                                />
                              )}
                            <span
                              className="anlyzepercntg"
                              style={
                                data4.response &&
                                Math.floor(
                                  data4.response.classes[1]["confidence"] * 100
                                ) === 0
                                  ? { paddingLeft: 0 }
                                  : {
                                      left:
                                        Math.floor(
                                          data4.response &&
                                            data4.response.classes[1][
                                              "confidence"
                                            ] * 100
                                        ) + "%"
                                    }
                              }
                            >
                              {data4.response
                                ? Math.floor(
                                    data4.response.classes[1]["confidence"] *
                                      100
                                  )
                                : null}
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="status-box">
                        <div className="clearfix">
                          <span className="anlyzetext">
                            {data4.response
                              ? data4.response.classes[2][
                                  "class_name"
                                ][0].toUpperCase() +
                                data4.response.classes[2]["class_name"].slice(1)
                              : null}
                          </span>
                        </div>
                        <div className="statussect">
                          <div className="statusbar">
                            {data4.response &&
                              Math.floor(
                                data4.response.classes[2]["confidence"] * 100
                              ) > 0 && (
                                <span
                                  className="statusbar-fill"
                                  style={{
                                    width:
                                      (data4.response
                                        ? Math.abs(
                                            Math.floor(
                                              data4.response.classes[2][
                                                "confidence"
                                              ] * 100
                                            )
                                          )
                                        : null) + "%"
                                  }}
                                />
                              )}
                            <span
                              className="anlyzepercntg"
                              style={
                                data4.response &&
                                Math.floor(
                                  data4.response.classes[2]["confidence"] * 100
                                ) === 0
                                  ? { paddingLeft: 0 }
                                  : {
                                      left:
                                        Math.floor(
                                          data4.response &&
                                            data4.response.classes[2][
                                              "confidence"
                                            ] * 100
                                        ) + "%"
                                    }
                              }
                            >
                              {data4.response
                                ? Math.floor(
                                    data4.response.classes[2]["confidence"] *
                                      100
                                  )
                                : null}
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
