import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { SyncLoader } from "react-spinners";

class InputDataPI extends Component {
  constructor() {
    super();
    this.state = {
      text: "",
      url: ""
    };
  }

  handleInputText = (text, type) => {
    if (type === "text") {
      this.setState({ text: text });
    } else {
      this.setState({ url: text });
    }
  };

  analyzeText = type => {
    this.props.clearPreviousData();
    if (type === "text" && this.state.text !== "") {
      this.props.analyzeEnteredText({
        text: `${this.state.text}`
      });
    } else if (type === "url" && this.state.url !== "") {
      this.props.analyzeEnteredText({
        url: `${this.state.url}`
      });
    } else {
      console.log("No Data to submit!!");
    }
  };

  render() {
    return (
      <div className="text-tab">
        <Tabs
          defaultActiveKey={1}
          id="uncontrolled-tab-example"
          onClick={() =>
            this.setState({ text: "", url: "" }, () =>
              this.props.clearPreviousData()
            )
          }
        >
          <Tab eventKey={1} title="Text">
            <textarea
              className="form-control textarea-tb"
              rows="4"
              id="comment"
              value={this.state.text}
              placeholder="Enter text..."
              onChange={event =>
                this.handleInputText(event.target.value, "text")
              }
            />
            <div className="button-sect text-right">
              <button
                type="submit"
                disabled={this.state.text === ""}
                onClick={() => this.analyzeText("text")}
                className="btn-default btn-style"
              >
                Analyse
              </button>
            </div>
          </Tab>
          <Tab eventKey={2} title="Link">
            <input
              type="text"
              className="form-control textbox textarea-tb"
              placeholder="Enter URL Link..."
              value={this.state.url}
              onChange={event =>
                this.handleInputText(event.target.value, "url")
              }
            />
            <div className="button-sect text-right">
              <button
                type="submit"
                disabled={this.state.url === ""}
                value="submit"
                onClick={() => this.analyzeText("url")}
                className="btn-default btn-style"
              >
                Analyse
              </button>
            </div>
          </Tab>
          {/* New functionality=> will be implemented soon */}
          {/* <Tab
            eventKey={3}
            onClick={() => this.props.clearPreviousData()}
            title="Choose"
          >
            <input
              type="text"
              className="form-control textbox textarea-tb"
              placeholder="Input placeholder..."
              onChange={event =>
                console.log("Input Text: ", event.target.value)
              }
            />
            <div className="button-sect text-right">
              <button
                type="submit"
                onClick={() => console.log("Under Construction")}
                className="btn-default btn-style"
              >
                Button Text
              </button>
            </div>
          </Tab> */}
        </Tabs>
        {this.props.loader ? (
          <div className="text-center" style={{ marginTop: "35px" }}>
            <SyncLoader color={"#785ef0"} loading={this.props.loader} />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default InputDataPI;
