/* eslint-disable */
import React, { Component } from "react";
import Alert from "react-s-alert";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect
} from "react-router-dom";

import Assistant from "./Assistant";
import baseURL from "./BaseURL";
import createBrowserHistory from "history/createBrowserHistory";
import Discovery from "./Discovery";
import ExpertAssist from "./ExpertAssist";
import HomeComponent from "./HomeComponent";
import NaturalLanguageUnderstanding from "./NaturalLanguageUnderstanding";
import PersonalityInsights from "./PersonalityInsights";
import VisualRecognition from "./VisualRecognition";

import "../css/style.css";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import hamburger from "../images/temp/Menu-Icon.svg";
import logo from "../images/temp/logo-white-new.png";

const initialState = {
  data: {},
  data1: {},
  data2: {},
  data3: {},
  data4: {},
  loader: false,
  route: "/naturalLanguageUnderstanding",
  menuClass: false,
  history: createBrowserHistory().location.pathname,
  logoUrl: "",
  customImageUrl: "",
  viewType: "",
  settingsStatus: false,
  menuClicked: false
};
let classifier_id;

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
    this.fetchData = this.fetchData.bind(this);
    this.fetchDataLan = this.fetchDataLan.bind(this);
    this.fetchDataPi = this.fetchDataPi.bind(this);
    this.fetchDataCp = this.fetchDataCp.bind(this);
    this.windowRefresh = this.windowRefresh.bind(this);
    this.setHistory = this.setHistory.bind(this);
    this.reset = this.reset.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleClassifierChange = this.handleClassifierChange.bind(this);
    this.logoSubmit = this.logoSubmit.bind(this);
    this.customImageUrl = this.customImageUrl.bind(this);
    this.viewType = this.viewType.bind(this);
  }

  settingsClicked = () => {
    if (this.state.menuClicked)
      this.setState({
        settingsStatus: !this.state.settingsStatus,
        menuClicked: false
      });
    else this.setState({ settingsStatus: !this.state.settingsStatus });
  };

  hamburgerMenuClicked = () => {
    if (this.state.settingsStatus)
      this.setState({
        settingsStatus: false,
        menuClicked: !this.state.menuClicked
      });
    else this.setState({ menuClicked: !this.state.menuClicked });
  };

  componentWillMount() {
    fetch(`${baseURL}/api/token`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.accessToken === "") {
          window.location = `${baseURL}/api/login`;
        } else {
          localStorage.setItem("userAccessToken", JSON.stringify(json));
        }
      });

    window.addEventListener("load", this.windowRefresh);
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener("load", this.windowRefresh);
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  reset() {
    this.setState(initialState);
  }

  windowRefresh() {
    localStorage.removeItem("input");
    this.reset();
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.menuClass) {
        this.onMenuClick();
      }
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  setHistory(history) {
    this.onMenuClick();
    this.setState({
      history: history
    });
  }

  handleClassifierChange(response) {
    classifier_id = response;
  }

  fetchData(type, value) {
    this.setState({ loader: true });
    fetch(`${baseURL}/api/nlu`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        type: type,
        input: value
      })
    })
      .then(response => {
        response.json().then(data => {
          if (data.error) {
            let err;
            if (data.error === "attempt to fetch failed: :nxdomain") {
              err = "Invalid URL";
            } else {
              err = "Not enough text to analyse";
            }
            Alert.error(err, {
              position: "bottom-right",
              effect: "slide",
              timeout: 2500
            });
          }
          this.setState({ data: data, loader: false });
        });
      })
      .catch(() => {});
  }

  fetchDataLan(type, value, response) {
    this.setState({ loader: true });
    fetch(`${baseURL}/api/nlc`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        type: type,
        text: value,
        classifier_id: classifier_id
      })
    })
      .then(response => {
        response.json().then(data => {
          if (data.error) {
            let err;
            if (data.error === "attempt to fetch failed: :nxdomain") {
              err = "Invalid URL";
              Alert.error(err, {
                position: "bottom-right",
                effect: "slide",
                timeout: 2500
              });
            } else if (data.error.error === "Phrase too long") {
              this.setState({ data4: data.error.error, loader: false });
            } else {
              err = "Not enough text to analyse";
              Alert.error(err, {
                position: "bottom-right",
                effect: "slide",
                timeout: 2500
              });
            }
          }
          this.setState({ data4: data, loader: false });
        });
      })
      .catch(() => {});
  }

  fetchDataPi(type, value, response) {
    this.setState({ loader: true });
    fetch(`${baseURL}/api/pi`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        type: type,
        input: value,
        response: "insights"
      })
    })
      .then(response => {
        response.json().then(data => {
          if (data.error) {
            Alert.error(data.error, {
              position: "bottom-right",
              effect: "slide",
              timeout: 3500
            });
          }
          this.setState({ data1: data, loader: false });
        });
      })
      .catch(() => {});
  }

  fetchDataCp(type, value, response) {
    this.setState({ loader: true });
    fetch(`${baseURL}/api/pi`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        type: type,
        input: value,
        response: "preferences"
      })
    })
      .then(response => {
        response.json().then(data => {
          if (data.error) {
            Alert.error(data.error, {
              position: "bottom-right",
              effect: "slide",
              timeout: 3500
            });
          }
          this.setState({ data2: data, loader: false });
        });
      })
      .catch(() => {});
  }

  onMenuClick() {
    this.setState({
      menuClass: !this.state.menuClass
    });
  }

  alertNoInput() {
    Alert.error("Please enter some input to analyse", {
      position: "bottom-right",
      effect: "slide",
      timeout: 2500
    });
  }

  logoSubmit(data) {
    if (data.target.files[0].type.indexOf("image") === -1) {
      Alert.error("Invalid file format", {
        position: "bottom-right",
        effect: "slide",
        timeout: 3000
      });
      return;
    } else {
      this.setState({
        logoUrl: URL.createObjectURL(data.target.files[0])
      });
    }
  }

  customImageUrl(event) {
    if (event.target.files[0].type.indexOf("image") === -1) {
      Alert.error("Invalid file format", {
        position: "bottom-right",
        effect: "slide",
        timeout: 3000
      });
      return;
    } else {
      this.setState({
        customImageUrl: URL.createObjectURL(event.target.files[0])
      });
    }
  }

  viewType(value) {
    this.setState({
      viewType: value.view
    });
  }

  render() {
    const { data, data1, data4 } = this.state;
    let headerClass = "";
    if (
      this.state.customImageUrl &&
      this.state.viewType === "custom" &&
      this.state.history === "/expertAssist"
    ) {
      let Background = this.state.customImageUrl;
      var sectionStyle = {
        backgroundImage: `url(${Background})`
      };
    }
    if (this.state.history === "/expertAssist") {
      headerClass = "chatcontentarea";
      if (this.state.history === "/expertAssist" && this.state.customImageUrl) {
        headerClass = "chatcontentarea custom-view";
      }
    }
    return (
      <Router>
        <div className={headerClass} style={sectionStyle}>
          <div className="top-header clearfix">
            <div className="row">
              <div className="col-md-3 col-xs-4">
                <div className="logo-arae">
                  <Link to="/">
                    <img src={logo} onClick={() => this.forceUpdate()} alt="" />
                  </Link>
                </div>
              </div>
              {document.location.pathname === "/" && (
                <div className="col-md-6 col-xs-4">
                  <h4 className="spacetime-caption">Demo System</h4>
                </div>
              )}
              {document.location.pathname ===
                "/naturalLanguageUnderstanding" && (
                <div className="col-md-6 col-xs-4">
                  <h4 className="spacetime-caption">
                    Natural Language Understanding
                  </h4>
                </div>
              )}
              {document.location.pathname === "/personalityInsights" && (
                <div className="col-md-6 col-xs-4">
                  <h4 className="spacetime-caption">Personality Insights</h4>
                </div>
              )}
              {document.location.pathname === "/assistant" && (
                <div className="col-md-6 col-xs-4">
                  <h4 className="spacetime-caption">Assistant</h4>
                </div>
              )}
              {document.location.pathname === "/expertAssist" && (
                <div className="col-md-6 col-xs-4">
                  <h4 className="spacetime-caption">Expert Assist</h4>
                </div>
              )}
              {document.location.pathname === "/discovery" && (
                <div className="col-md-6 col-xs-4">
                  <h4 className="spacetime-caption">Discovery</h4>
                </div>
              )}
              {document.location.pathname === "/visualRecognition" && (
                <div className="col-md-6 col-xs-4">
                  <h4 className="spacetime-caption">Visual Recognition</h4>
                </div>
              )}
              <div className="col-md-3 col-xs-4" ref={this.setWrapperRef}>
                <nav className="navbar">
                  <span
                    className="hamburgermenu"
                    onClick={() => this.hamburgerMenuClicked()}
                  >
                    <img src={hamburger} alt="" />
                  </span>
                </nav>
              </div>
            </div>
          </div>

          <div
            className={
              this.state.menuClicked
                ? "hamburger-menulist active"
                : "hamburger-menulist"
            }
          >
            <ul className="navigatiolist">
              <li
                id="list1"
                className={
                  this.state.history === "/naturalLanguageUnderstanding" ||
                  (!localStorage.getItem("input") &&
                    this.state.history !== "/visualRecognition" &&
                    this.state.history !== "/expertAssist")
                    ? "active"
                    : ""
                }
                onClick={() => {
                  this.setHistory("/naturalLanguageUnderstanding");
                  this.hamburgerMenuClicked();
                }}
              >
                <Link to="/naturalLanguageUnderstanding">
                  Natural Language Understanding
                </Link>
              </li>

              <li
                id="list2"
                className={
                  this.state.history === "/personalityInsights" ? "active" : ""
                }
              >
                <Link
                  to={"/personalityInsights"}
                  onClick={() => {
                    this.setHistory("/personalityInsights");
                    this.hamburgerMenuClicked();
                  }}
                >
                  Personality Insights
                </Link>
              </li>

              <li
                id="list6"
                className={this.state.history === "/assistant" ? "active" : ""}
              >
                <Link
                  to="/assistant"
                  onClick={() => {
                    this.setHistory("/assistant");
                    this.hamburgerMenuClicked();
                  }}
                >
                  Assistant
                </Link>
              </li>

              <li
                id="list5"
                className={
                  this.state.history === "/expertAssist" ? "active" : ""
                }
              >
                <Link
                  to="/expertAssist"
                  onClick={() => {
                    this.setHistory("/expertAssist");
                    this.hamburgerMenuClicked();
                  }}
                >
                  Expert Assist
                </Link>
              </li>

              <li
                id="list7"
                className={this.state.history === "/discovery" ? "active" : ""}
              >
                <Link
                  to="/discovery"
                  onClick={() => {
                    this.setHistory("/discovery");
                    this.hamburgerMenuClicked();
                  }}
                >
                  Discovery
                </Link>
              </li>

              <li
                id="list4"
                className={
                  this.state.history === "/visualRecognition" ? "active" : ""
                }
              >
                <Link
                  to="/visualRecognition"
                  onClick={() => {
                    this.setHistory("/visualRecognition");
                    this.hamburgerMenuClicked();
                  }}
                >
                  Visual Recognition
                </Link>
              </li>
            </ul>
          </div>

          <Route exact path="/" render={() => <HomeComponent />} />
          <Route
            exact
            path="/naturalLanguageUnderstanding"
            render={props => {
              return (
                <div className="content-section">
                  <NaturalLanguageUnderstanding
                    data={data}
                    data4={data4}
                    fetchDataLan={this.fetchDataLan}
                    fetchData={this.fetchData}
                    history={props.history}
                    loader={this.state.loader}
                    classifierid={this.handleClassifierChange}
                    menuActive={this.state.menuClicked}
                    settingsClicked={this.settingsClicked} //Callback to update state value from child
                    settingsActive={this.state.settingsStatus} //Updated state to children
                  />
                </div>
              );
            }}
          />
          <Route
            path="/personalityInsights"
            render={() => {
              return (
                <div className="content-section">
                  <PersonalityInsights
                    menuClicked={this.hamburgerMenuClicked} //Callback to update state value from child
                    menuActive={this.state.menuClicked}
                  />
                </div>
              );
            }}
          />
          <Route
            path="/expertAssist"
            render={() => {
              return (
                <div className="content-section">
                  <ExpertAssist
                    menuClicked={this.hamburgerMenuClicked}
                    menuActive={this.state.menuClicked}
                    settingsClicked={this.settingsClicked} //Callback to update state value from child
                    settingsActive={this.state.settingsStatus} //Updated state to children
                  />
                </div>
              );
            }}
          />

          <Route
            path="/assistant"
            render={() => {
              return (
                <div>
                  <Assistant
                    menuClicked={this.hamburgerMenuClicked}
                    menuActive={this.state.menuClicked}
                    settingsClicked={this.settingsClicked} //Callback to update state value from child
                    settingsActive={this.state.settingsStatus} //Updated state to children
                  />
                </div>
              );
            }}
          />

          <Route
            path="/discovery"
            render={() => {
              return (
                <div className="content-section">
                  <Discovery
                    menuClicked={this.hamburgerMenuClicked} //Callback to update state value from child
                    menuActive={this.state.menuClicked}
                    settingsClicked={this.settingsClicked} //Callback to update state value from child
                    settingsActive={this.state.settingsStatus} //Updated state to children
                  />
                </div>
              );
            }}
          />

          <Route
            path="/visualRecognition"
            render={() => {
              return (
                <div className="content-section">
                  <VisualRecognition />
                </div>
              );
            }}
          />
        </div>
      </Router>
    );
  }
}

export default Header;
