// Author: R Rahul, c706
import React, { Component } from "react";
import Alert from "react-s-alert";

import ChatScreen from "../components/ChatScreen";
import Wrapper from "./Wrapper";
import workspace_id from "./Workspace";

import AssistantBackground from "../images/temp/AssistantBackground.png";
import chatIcon from "../images/temp/chat.png";
import customViewIcon from "../images/temp/Chat-View-Select-Icon.svg";
import defaultViewIcon from "../images/temp/Default-View-Select-Icon.svg";
import iphoneViewIcon from "../images/temp/Iphone-View-Select-Icon.svg";
import settingsbutton from "../images/temp/Settings-Icon.svg";

class Assistant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assistants: [],
      audioMode: false,
      customImageUrl: "",
      customView: false,
      defaultView: true,
      engagementTracker: false,
      iphoneView: false,
      menuActive: this.props.menuActive,
      newAgentIcon: "",
      selectedAssistant: "",
      setactive: this.props.settingsActive,
      toggleCustomView: true
    };
  }

  componentDidMount() {
    this.setState({
      customImageUrl: AssistantBackground,
      assistants: workspace_id
    });
  }

  //Handle uploading custom file
  customImageUpload(event) {
    if (event.target.files[0].type.indexOf("image") === -1) {
      Alert.error("Invalid file format", {
        position: "bottom-right",
        effect: "slide",
        timeout: 3000
      });
      return;
    } else {
      this.setState({
        customImageUrl: URL.createObjectURL(event.target.files[0])
      });
    }
  }

  //Handle uploading custom file
  agentIconUpload(event) {
    if (event.target.files[0].type.indexOf("image") === -1) {
      Alert.error("Invalid file format", {
        position: "bottom-right",
        effect: "slide",
        timeout: 3000
      });
      return;
    } else {
      this.setState({
        newAgentIcon: URL.createObjectURL(event.target.files[0])
      });
    }
  }

  // Used to handle the settings button click
  settingClick = () => {
    this.props.settingsClicked(); //Call back to parent component(Header.js)
  };

  //New updated props from header.js to toggle settings and menu view
  componentWillReceiveProps = newProps => {
    this.setState({
      setactive: newProps.settingsActive,
      menuActive: newProps.menuActive
    });
  };

  // Used to switch the view between desktop, iPhone or custom View
  changeViewMode = type => {
    switch (type) {
      case "iphone":
        this.setState({
          defaultView: false,
          iphoneView: true,
          customView: false
        });
        break;
      case "custom":
        this.setState({
          defaultView: false,
          iphoneView: false,
          customView: true
        });
        break;
      default:
        this.setState({
          defaultView: true,
          iphoneView: false,
          customView: false
        });
        break;
    }
  };

  componentWillUnmount() {
    document.body.style.backgroundImage = null;
    document.body.style.backgroundSize = null;
  }
  changeAssistant = id => {
    this.setState({
      selectedAssistant: id
    });
  };

  render() {
    let background = this.state.customImageUrl;

    var customImage = {
      backgroundImage: `url(${background})`,
      size: "100%",
      repeat: "no-repeat",
      minHeight: "100vh"
    };
    if (this.state.customView) {
      document.body.style.backgroundImage = customImage.backgroundImage;
      document.body.style.backgroundSize = customImage.size;
      document.body.style.backgroundRepeat = customImage.repeat;
      document.body.style.minHeight = customImage.minHeight;
    } else {
      document.body.style.backgroundImage = null;
      document.body.style.backgroundSize = null;
    }

    return (
      <div className={this.state.customView ? "" : "content-section "}>
        {/* Sidebar Starts here!! */}

        <div className="settingtbutton-img" onClick={this.settingClick}>
          <img src={settingsbutton} alt="" />
        </div>

        <div
          className={
            this.state.setactive ? "st-settings active" : "st-settings"
          }
        >
          <div className={this.state.loader ? "settings-overlay" : ""}>
            <ul className="st-setting-sec">
              <li>
                <div>
                  <label>Assistant</label>
                  <div className="select-drops">
                    <select
                      className="form-control"
                      onChange={event =>
                        this.changeAssistant(event.target.value)
                      }
                    >
                      <option value="">Select</option>
                      {this.state.assistants.map(assistant => (
                        <option value={assistant.id} key={assistant.id}>
                          {assistant.name}
                        </option>
                      ))}
                    </select>
                    <i className="fa fa-caret-down" aria-hidden="true" />
                  </div>
                </div>
              </li>

              <li>
                <label>Engagement Tracker</label>
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={() =>
                      this.setState({
                        engagementTracker: !this.state.engagementTracker
                      })
                    }
                  />
                  <span className="slider round" />
                </label>
              </li>

              <li>
                <label>Audio Mode</label>
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={() =>
                      this.setState({
                        audioMode: !this.state.audioMode
                      })
                    }
                  />
                  <span className="slider round" />
                </label>
              </li>

              {/* Used to switch between screen views. iPhone, Desktop and custom view are available */}
              <li>
                <label>Mode</label>
                <div
                  className="workspace-type new-wrkspace"
                  onClick={() => this.changeViewMode("default")}
                >
                  <input
                    type="radio"
                    name="viewId"
                    value="default"
                    checked={this.state.defaultView}
                    readOnly
                  />
                  <img src={defaultViewIcon} alt="" />
                </div>

                <div
                  className="workspace-type new-wrkspace"
                  onClick={() => this.changeViewMode("iphone")}
                >
                  <input
                    type="radio"
                    name="viewId"
                    value="iphone"
                    checked={this.state.iphoneView}
                    readOnly
                  />
                  <img src={iphoneViewIcon} alt="" />
                </div>

                <div
                  className="workspace-type new-wrkspace"
                  onClick={() => this.changeViewMode("custom")}
                >
                  <input
                    type="radio"
                    name="viewId"
                    value="custom"
                    checked={this.state.customView}
                    readOnly
                  />
                  <img src={customViewIcon} alt="" />
                  <input
                    className="customimageupload"
                    type="file"
                    onChange={event => this.customImageUpload(event)}
                  />
                </div>
              </li>
              <li>
                <div className="btn-upload">
                  <input
                    type="file"
                    value=""
                    onChange={event => this.agentIconUpload(event)}
                  />
                  Upload Icon
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* Sidebar Ends here!! */}

        <div
          className={
            this.state.customView &&
            (this.state.setactive || this.state.menuActive)
              ? "expert-assist open-setting"
              : "expert-assist"
          }
        >
          <div
            className={
              this.state.customView
                ? "chatwindow-movable assistant"
                : "assistant"
            }
          >
            <div
              className={
                (this.state.defaultView
                  ? "st-chat-window clearfix remove-iphonex chatmain-window"
                  : undefined) ||
                (this.state.customView
                  ? this.state.toggleCustomView
                    ? "st-chat-window clearfix custom chat-open"
                    : "st-chat-window clearfix custom"
                  : undefined) ||
                (this.state.iphoneView ? "st-chat-window clearfix" : undefined)
              }
            >
              <Wrapper>
                {this.state.customView && this.state.toggleCustomView && (
                  <div className="chat-title">Let's talk</div>
                )}
                <ChatScreen
                  newAgentIcon={this.state.newAgentIcon}
                  menuClicked={this.props.menuClicked}
                  menuActive={this.props.menuActive}
                  settingsActive={this.props.settingsActive}
                  settingsClicked={this.props.settingsClicked}
                  audioMode={this.state.audioMode}
                  engagementTracker={this.state.engagementTracker}
                  selectedAssistant={this.state.selectedAssistant}
                />
              </Wrapper>
            </div>
            {this.state.customView && (
              <div
                className="chat-icon"
                onClick={() =>
                  this.setState({
                    toggleCustomView: !this.state.toggleCustomView
                  })
                }
              >
                <img src={chatIcon} alt="" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default Assistant;
