const workspace_id = [
  {
    name: "Assistant 01",
    id: "f930d723-3711-4a50-acc0-a0a90069cc6f"
  },
  {
    name: "Assistant 02",
    id: "15fb25a6-d28f-4e30-93c9-49e795a669c8"
  },
  {
    name: "Assistant 03",
    id: "133b4245-bb71-4336-bfaf-7a9996504bca"
  }
];

export default workspace_id;