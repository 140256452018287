import React, { Component } from "react";
import SpiderGraph from "../components/SpiderGraph";
import "../css/style.css";

export default class AnalyzeBox extends Component {
  render() {
    const { consumerNeeds, consumerValues, personalityGraphData } = this.props;

    return (
      <div>
        <section className="inner-sections">
          <div className="row">
            <SpiderGraph
              personalityGraphData={personalityGraphData}
              personalityInsight
              heading={this.props.headings[0]}
            />
            <div className="col-md-3">
              <div className="analyze-box lan-ht">
                <h5>{this.props.headings[1]}</h5>
                <div className="analyze-box entities">
                  <div className="hr-div clearfix" />
                  {consumerNeeds.map(needs => (
                    <div key={needs.id} className="status-box">
                      <div className="clearfix">
                        <span className="anlyzetext">{needs.name}</span>
                      </div>
                      <div className="statussect">
                        <div className="statusbar">
                          {Math.floor(needs.percentile * 100) > 0 ? (
                            <span
                              className="statusbar-fill"
                              style={{
                                width: Math.floor(needs.percentile * 100) + "%"
                              }}
                            />
                          ) : (
                            ""
                          )}
                          <span
                            className="anlyzepercntg"
                            style={
                              Math.floor(needs.percentile * 100) <= 0
                                ? { paddingLeft: 0 }
                                : {
                                    left:
                                      Math.abs(
                                        Math.floor(needs.percentile * 100)
                                      ) + "%"
                                  }
                            }
                          >
                            {Math.floor(needs.percentile * 100)}%
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="analyze-box lan-ht">
                <h5>{this.props.headings[2]}</h5>
                <div className="hr-div clearfix" />
                <div className="analyze-box entities">
                  <div>
                    {consumerValues.map(value => (
                      <div key={value.id} className="status-box">
                        <div className="clearfix">
                          <span className="anlyzetext">{value.name}</span>
                        </div>
                        <div className="statussect">
                          <div className="statusbar">
                            {Math.floor(value.percentile * 100) > 0 && (
                              <span
                                className="statusbar-fill"
                                style={{
                                  width:
                                    Math.abs(
                                      Math.floor(value.percentile * 100)
                                    ) + "%"
                                }}
                              />
                            )}
                            <span
                              className="anlyzepercntg"
                              style={
                                Math.floor(value.percentile * 100) === 0
                                  ? { paddingLeft: 0 }
                                  : {
                                      left:
                                        Math.floor(value.percentile * 100) + "%"
                                    }
                              }
                            >
                              {Math.floor(value.percentile * 100)}%
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
