import React, { Component } from "react";
// import jquery from 'jquery';
// require('./js/jquery-3.1.1.min');
// require('./js/bootstrap.min');
// import NaturalLanguageUnderstanding from './components/NaturalLanguageUnderstanding';
import Header from "./components/Header";
import "./css/style.css";
import "./css/chat.css";
import Alert from "react-s-alert";
import "react-s-alert/dist/s-alert-css-effects/flip.css";

class App extends Component {
  componentDidMount() {
    function warning() {
      localStorage.removeItem("input");
      //localStorage.clear();
    }
    window.onbeforeunload = warning();
  }

  render() {
    return (
      <div>
        <Header />
        <Alert stack={{ limit: 3 }} />
      </div>
    );
  }
}

export default App;
