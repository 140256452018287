import React from "react";

const Summary = props => {
  return (
    <div className="row" style={{ marginBottom: "50px" }}>
      <div className="col-md-8 text-justify">
        <b>Summary</b>
        <span
          style={{
            marginBottom: "10px",
            display: "block",
            fontSize: "12px"
          }}
        >
          {props.summaryData.quality}(
          {props.summaryData.wordCount ? props.summaryData.wordCount : 0} words)
        </span>
        <p>{props.summaryData.summaryText}</p>
      </div>
      <div className="col-md-4">
        <b>You are likely to:</b>
        <ul>
          {props.summaryData.likely.map((likely, index) => (
            <li key={index}>{likely}</li>
          ))}
        </ul>
        <b>You are unlikely to:</b>
        <ul>
          {props.summaryData.unlikely.map((unlikely, index) => (
            <li key={index}>{unlikely}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Summary;
