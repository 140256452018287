/*eslint-disable */
import React, { Component } from "react";

let d3;
let createV4SelectableForceDirectedGraph;
class Graph extends Component {
  constructor(props) {
    super(props);
    d3 = window.d3;
    createV4SelectableForceDirectedGraph =
      window.createV4SelectableForceDirectedGraph;
  }

  componentDidMount() {
    const { searchData } = this.props;
    const searchFilter = [];
    searchData &&
      searchData.nodes.map(item => {
        if (!searchFilter.includes(item.type)) {
          searchFilter.push(item.type);
        }
      });
    var nodes = {
      links: searchData.links,
      nodes: searchData.nodes
    };

    var svg = d3.select("#d3_selectable_force_directed_graph");
    createV4SelectableForceDirectedGraph(svg, nodes, searchFilter);
  }

  render() {
    var divStyle = {
      width: "100%",
      height: "calc(100vh - 175px)",
      margin: "auto",
      border: "0",
      marginBottom: "12px",
        color: "#ccc"
    };
    return (
      <div
        className="graphsection"
        id="d3_selectable_force_directed_graph"
        style={divStyle}
      >
        <svg />
      </div>
    );
  }
}

export default Graph;
