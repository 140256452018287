import React, { Component } from "react";
import "../css/style.css";
import TagCloud from "react-tag-cloud";
import randomColor from "randomcolor";
import uuid from "uuid";

class WordClouds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cloud: 400
    };
  }

  componentDidMount() {
    if (this.myInput.offsetWidth > 500) {
      this.setState({
        cloud: 450
      });
    } else {
      this.setState({
        cloud: 300
      });
    }
  }

  render() {
    const { data } = this.props;
    return (
      <div>
        <section className="inner-sections">
          <div className="row">
            <div className="col-sm-6">
              <div
                className="analyze-imgs"
                ref={input => {
                  this.myInput = input;
                }}
              >
                <h5>Keywords</h5>
                <div className="hr-div clearfix" />
                {data.keywords && data.keywords.length > 0 && (
                  <div className="cloud">
                    <TagCloud
                      style={{
                        fontFamily: "sans-serif",
                        fontSize: 30,
                        fontWeight: "bold",
                        color: () => randomColor({ hue: "blue" }),
                        padding: 4,
                        width: "100%",
                        height: "100%",
                        flex: 1
                      }}
                    >
                      {data.keywords.map(keyword => (
                        <div
                          key={uuid.v4()}
                          style={{
                            fontSize:
                              keyword.value > 0.5
                                ? keyword.value > 0.8
                                  ? keyword.value * 50
                                  : keyword.value * 35
                                : keyword.value > 0.3
                                ? keyword.value * 20
                                : keyword.value * 10
                          }}
                        >
                          {keyword.text}
                        </div>
                      ))}
                    </TagCloud>
                  </div>
                )}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="analyze-imgs">
                <div className="">
                  <div className="row">
                    <div
                      className={
                        data.entities.length > 0 ? "col-md-6" : "col-md-12"
                      }
                    >
                      <h5>Concepts</h5>
                      <div className="analyze-box entities">
                        <div className="hr-div clearfix" />
                        {data.concepts &&
                          data.concepts.length > 0 &&
                          data.concepts.map((concept, index) => (
                            <div className="status-box" key={index}>
                              <div className="clearfix">
                                <span className="anlyzetext">
                                  {concept.text}
                                </span>
                              </div>
                              <div className="statussect">
                                <div className="statusbar">
                                  {Math.floor(concept.value * 100) > 0 && (
                                    <span
                                      className="statusbar-fill"
                                      style={{
                                        width:
                                          Math.floor(concept.value * 100) + "%"
                                      }}
                                    />
                                  )}
                                  <span
                                    className="anlyzepercntg"
                                    style={
                                      Math.floor(concept.value * 100) === 0
                                        ? { paddingLeft: 0 }
                                        : {
                                            left:
                                              Math.floor(concept.value * 100) +
                                              "%"
                                          }
                                    }
                                  >
                                    {Math.floor(concept.value * 100)}%
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                    {data.entities.length > 0 && (
                      <div className="col-md-6">
                        <h5>Entities</h5>
                        <div className="hr-div clearfix" />
                        <div className="analyze-box entities">
                          {data.entities &&
                            data.entities.length > 0 &&
                            data.entities.map((entity, index) => (
                              <div className="status-box" key={index}>
                                <div className="clearfix">
                                  <span className="anlyzetext">
                                    {entity.text}
                                  </span>
                                </div>
                                <div className="statussect">
                                  <div className="statusbar">
                                    {Math.floor(entity.value * 100) > 0 && (
                                      <span
                                        className="statusbar-fill"
                                        style={{
                                          width:
                                            Math.floor(entity.value * 100) + "%"
                                        }}
                                      />
                                    )}
                                    <span
                                      className="anlyzepercntg"
                                      style={
                                        Math.floor(entity.value * 100) === 0
                                          ? { paddingLeft: 0 }
                                          : {
                                              left:
                                                Math.floor(entity.value * 100) +
                                                "%"
                                            }
                                      }
                                    >
                                      {Math.floor(entity.value * 100)}%
                                    </span>
                                  </div>
                                </div>
                                <div className="entity-type">{entity.type}</div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default WordClouds;
