import React, { Component } from "react";
import InputAnalyze from "../components/InputAnalyze";
import WordClouds from "../components/WordClouds";
import AnalyzeBox from "../components/AnalyzeBox";
import Alert from "react-s-alert";
import settingsbutton from "../images/temp/Settings-Icon.svg";
import "../css/style.css";

class NaturalLanguageUnderstanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classifierId: "",
      setactive: this.props.settingsActive,
      classifierData: "",
      classifier: ""
    };
    this.classifierIdChange = this.classifierIdChange.bind(this);
    this.settingClick = this.settingClick.bind(this);
  }

  classifierIdChange(event) {
    const val = event.target.value;
    this.setState({ classifier: val });
    this.props.classifierid(this.state.classifier);
  }

  settingClick() {
    this.props.settingsClicked(); //Call back to parent component(Header.js)
  }

  componentDidMount() {
    fetch("https://spacetime-back.au-syd.mybluemix.net/api/listclassifiers", {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        response.json().then(datal => {
          if (datal.error) {
            Alert.error(datal.error, {
              position: "bottom-right",
              effect: "slide",
              timeout: 3500
            });
          }
          this.setState({ classifierData: datal.classifiers });
          this.setState({ classifier: datal.classifiers[0].classifier_id });
          this.props.classifierid(this.state.classifier);
        });
      })
      .catch(() => {});
  }

  //New updated props from header.js to toggle settings and menu view
  componentWillReceiveProps = newProps => {
    this.setState({
      setactive: newProps.settingsActive
    });
  };

  render() {
    return (
      <div>
        <div
          className={
            this.state.setactive
              ? "settingtbutton-img active"
              : "settingtbutton-img"
          }
          onClick={this.settingClick}
        >
          <img src={settingsbutton} alt="" />
        </div>
        <InputAnalyze
          fetchData={this.props.fetchData}
          data={this.props.data}
          loader={this.props.loader}
          fetchDataPi={this.fetchDataPi}
          fetchDataLan={this.props.fetchDataLan}
        />
        {this.props.data.keywords ? (
          <div>
            <WordClouds
              fetchData={this.props.fetchData}
              data={this.props.data}
            />
            <AnalyzeBox
              headings={["Emotion", "Sentiment", "Natural Language Classifier"]}
              fetchData={this.props.fetchData}
              data={this.props.data}
              data4={this.props.data4}
              fetchDataLan={this.props.fetchDataLan}
            />
          </div>
        ) : (
          <div />
        )}
        <div
          className={
            this.state.setactive ? "st-settings active" : "st-settings"
          }
        >
          <ul className="st-setting-sec">
            <h4>Configuration Settings</h4>
            <li>
              <label>Classifier Id </label>
              <select
                className="form-control"
                value={this.state.classifier}
                onChange={this.classifierIdChange}
              >
                {this.state.classifierData
                  ? this.state.classifierData.map((classifier, index) => (
                      <option value={classifier.classifier_id} key={index}>
                        {classifier.name}
                      </option>
                    ))
                  : ""}
              </select>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default NaturalLanguageUnderstanding;
